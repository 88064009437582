.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.modalHeader h5 {
  margin: 0;
}
.closeButton {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 5px;
}
.closeButton:hover {
  cursor: pointer;
  background-color: #69696999;
}
.modalHeader > span {
  cursor: pointer;
  font-size: 25px;
}

.modalLineHeader {
  background-color: var(--warning-color);
  height: 7px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.secondaryTitle {
  color: var(--gray-color);
  font-size: 12.5px;
  font-weight: 300;
}

@media (max-width: 391px) {
  .modalHeader h5 {
    font-size: 18px;
  }
  .modalHeader > *:last-child {
    width: 32px;
    margin-right: -14px;
  }
}

.navLink {
    position: relative;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0px;
    margin: 10px;
    font-size: 13px;
}

.navLink:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.selected {
    color: var(--primary-color) !important;
    border-bottom: 2px var(--primary-color) solid;
    /* text-shadow: 0 0 10px var(--primary-color); */
}

.bottomLine {
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: var(--primary-color);
    bottom: 0;
    left: 0;
    animation: bottom-line-hover 2s linear ease;
}

.navLink:hover .bottomLine {
    animation: showBottomLineHover .25s ease-in-out forwards;
}

.navLink:not(:hover) .bottomLine {
    animation: hideBottomLineHover .25s ease-in-out forwards;
    opacity: 0;
    transition: opacity .6s;
}

.selected:hover .bottomLine {
    animation: none !important;
}

.selected:not(:hover) .bottomLine {
    animation: none !important;
}

@keyframes showBottomLineHover {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@keyframes hideBottomLineHover {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}
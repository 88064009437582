.container {
  display: flex;
  flex-direction: column;
}
.image {
  height: 100px;
  margin-bottom: 40px;
  object-fit: contain;
}
.title {
  font-size: 23px;
  text-align: center;
}
.description {
  color: var(--gray-color);
  font-size: 14px;
  text-align: center;
}

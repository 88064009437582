.tableButton {
  background-color: var(--background-color);
}

.light .tableButton {
  color: var(--primary-color);
  background-color: #fff;
}
.light .tableButton:hover {
  color: #051a1f;
  background-color: var(--primary-color) !important;
}

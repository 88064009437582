.avatarsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* Old: Between */
  margin: 25px 0;
}

.avatarButton {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.avatarButton:hover {
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.buttonsContainer > * {
  flex: 0.485;
}

.selectedAvatar {
  outline: 3px var(--primary-color) solid;
}

.pageContainer {
  /* background-color: black; */
  /* color: white; */
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 60px;
}

/* ---------- PAGE HEADER ---------- */
.pageHeader {
  text-align: center;
  padding: 140px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.headerBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.pageHeader span {
  color: var(--primary-color);
  font-size: 20px;
}
.pageHeader h1 {
  font-weight: bold;
  font-size: 4rem;
}

/* ------------ ERROR ------------ */
.errorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 120px auto;
  gap: 20px;
}
.errorContainer > span {
  /* color: var(--danger-color); */
  font-size: 40px;
  text-align: center;
}

/* ---------- PAGE GRID ---------- */
.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, auto); */
  gap: 50px;
  padding: 40px 0;
}

@media (max-width: 780px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .pageHeader {
    padding: 0;
  }
}

/* ---------- PAGINATION ---------- */
.paginationContainer {
  margin: 40px 0 60px 0;
}

.totalAmountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 12px 0;
}
.totalAmountTitle {
  color: var(--gray-color);
  font-size: 12px;
}
.totalAmountValue {
}

.detailsContainer {
  border-radius: 10px;
  background-color: var(--background-color-inverted);
  padding: 12px;
}
.infoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 12px;
}

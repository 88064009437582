.validAddress {
  font-size: 11.5px;
  font-weight: 300;
  color: var(--success-color);
  transform: translateY(-16px);
}

.addressNotValid {
  font-size: 11.5px;
  font-weight: 300;
  color: var(--danger-color);
  transform: translateY(-16px);
}

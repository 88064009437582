/* !! input text color bug fix !! */
input,
textarea {
  color: var(--text-color);
}
/* !!------------!! */

.input {
  width: 100%; /*Small screen sizes fix*/

  font-size: 16px;
  padding: 7px 12px;
  margin: 2px 2px;
  outline: none;
  border: none;
  border-radius: 0px;
  background: none;
  flex: 1;
}
input::placeholder,
textarea::placeholder {
  font-weight: 300 !important;
  font-size: 14px;
  color: var(--gray-color);
}

.textareaInput {
  font-size: 16px;
  padding: 7px 12px;
  margin: 2px 2px;
  outline: none;
  border: none;
  border-radius: 0px;
  background: none;
  flex: 1;
}
.textAreanputIconContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 0px;
  border-radius: 5px;
  margin: 20px 0 30px 0;
  transition: border 0.3s ease;
  outline: 1.45px var(--secondary-color) solid;
  background-color: var(--secondary-color);
  overflow: hidden;
  /* height: 42px; */
}
.textAreanputIconContainer:not(:focus-within) {
  transition: outline 0.3s ease;
}
.textAreanputIconContainer:focus-within {
  outline-color: var(--primary-color);
}

/* .input::placeholder {
  color: #9295a6;
} */
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
  /* caret-color: #00191E !important; */
}
.passwordInput:-webkit-autofill,
.passwordInput:-webkit-autofill:hover,
.passwordInput:-webkit-autofill:focus,
.passwordInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
  /* caret-color: #00191E !important; */
}

.requiredSpan {
  color: var(--primary-color);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.inputIconContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 12px;
  border-radius: 5px;
  margin: 20px 0 30px 0;
  transition: border 0.3s ease;
  outline: 1.45px var(--secondary-color) solid;
  background-color: var(--secondary-color);
  overflow: hidden;
  height: 42px;
}
.inputIconContainer:not(:focus-within) {
  transition: outline 0.3s ease;
}
.inputIconContainer:focus-within {
  outline-color: var(--primary-color);
}
.customInputIconContainer {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 20px 0 30px 0;
}
.customInputIconContainer > * {
  width: 100%;
}
.labelSpan {
  /* color: #6c7080; */
  color: var(--gray-color);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 11px;
  width: 100%;
}

.errorSpan {
  position: absolute;
  top: 60px;
  font-size: 11px;
  color: var(--negative-color);
}
.textareaErrorSpan {
  top: unset;
  bottom: 13.5px;
}
.radioErrorSpan {
  font-size: 11px;
  color: var(--negative-color);
}

.errorInput {
  outline: 1px var(--negative-color) solid !important;
}

.indicatorLabel {
  font-size: 11px;
  color: var(--gray-color);
  margin-top: -17px;
  transform: translateY(-4px);
  margin-left: auto;
}

.disabled,
.disabled > input {
  background-color: #2a2f36 !important;
  color: #545766;
  cursor: not-allowed;
}
.disabled:focus-within {
  outline: none !important;
  border: none;
}
.light .disabled,
.light .disabled > input {
  background-color: #bfc4ca !important;
}

.inputIconContainer:focus-within .inputIcon {
  color: var(--primary-color);
}
.inputIcon {
  transition: color 0.3s ease;
}
/* ---------- PASSWORD INPUT CONTAINER ---------- */
.passwordInputContainer {
  padding: 9px 14px 7px 14px;
  border-radius: 5px;
  outline: none;
  margin: 20px 0 33px 0;
  outline: none;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  height: 42px;
}

.passwordInputContainer:focus-within {
  outline: 1.45px var(--primary-color) solid;
}

/* eye container */
.passwordInputContainer div {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.passwordInputContainer div:hover {
  background-color: #80808029;
  cursor: pointer;
}

.passwordInputContainer input {
  background-color: var(--secondary-color);
  border: none;
  width: 100%;
  font-size: 16px;
}

.passwordInputContainer input:focus {
  outline: none;
}

.passwordInputContainer input::placeholder {
  color: #9295a6;
}

/* ---------- PHONE NUMBER ---------- */
.phoneInput:focus {
  box-shadow: none !important;
}

.phoneInputContainer .open {
  background-color: none !important;
  border: none !important;
  outline: none !important;
}
.phoneInputContainer > div,
.phoneInputContainer > div > div {
  background-color: none !important;
  border: none !important;
  outline: none !important;
}
.phoneInputContainer > div > div:hover {
  background: none !important;
}

.phoneInputContainer li:hover {
  background-color: var(--primary-color-opacity) !important;
  color: var(--primary-color) !important;
}
.phoneInputContainer input {
}

.phoneInputContainer .active {
  /* background-color: red !important; */
}
.dropdownPhoneSelect {
  background-color: var(--background-color) !important;
  font-size: 13px !important;
  padding: 6px !important;
  border-radius: 5px;
  border: 0.5px solid var(--border-color) !important;
  font-family: 'Outfit', 'Overpass', sans-serif;
  width: 220px !important;
}

/* -------SELECT------ */
.selectContainer .inputIconContainer {
  padding-right: 0;
}
.selectInputPlaceholder {
  color: var(--gray-color);
  font-size: 15px;
  font-weight: 300;
}
.optionSpan {
  font-size: 15px;
}

/* -------RADIOS------ */
.radiosContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  margin-top: 30px;
}
/* -------DATE PICKER------ */
.datePickerContainer {
  padding: 0 10px;
}

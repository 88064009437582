.tabContainer {
  flex: 0.66;
  display: flex;
  flex-direction: column;
}

/* logo image */
.tabContainer img {
  min-width: 152px;
  width: 20%;
  margin-left: auto;
  margin-right: 10%;
  padding: 20px;
}

.formDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 7% 20% 20% 35%;
}

.title {
  font-size: 33px;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  line-height: 38px;
}

.mailInput {
  font-size: 16px;
  background-color: #00191e;
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  border: none;
}

.mailInput::placeholder {
  color: #9295a6;
}

.mailInput:focus {
  outline: 1px #00e6e6 solid;
}

.passwordInputContainer {
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  background-color: #00191e;
  display: flex;
  align-items: center;
}

.passwordInputContainer:focus-within {
  outline: 1px #00e6e6 solid;
}

/* eye container */
.passwordInputContainer div {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.passwordInputContainer div:hover {
  background-color: #80808029;
  cursor: pointer;
}

.passwordInputContainer input {
  background-color: #00191e;
  border: none;
  width: 100%;
  font-size: 16px;
}

.passwordInputContainer input:focus {
  outline: none;
}

.passwordInputContainer input::placeholder {
  color: #9295a6;
}

.forgotPassword {
  color: var(--primary-color);
  margin-left: auto;
  width: fit-content;
  font-size: 12px;
}

.registerSpan {
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
}

.registerSpan a {
  color: var(--primary-color);
}

.otpInput {
  margin-top: 20%;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .formDiv {
    padding: 3vh 0 0 0;
    width: 75vw;
    justify-self: center;
    align-self: center;
  }
  .title {
    text-align: center;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

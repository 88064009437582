.container {
  width: 90vw;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 20px;
  height: 600px;
}

.container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

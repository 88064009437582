.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 7% 10% 20% 25%;
  justify-content: center;
  gap: 20px;
}

.container h1 {
  font-size: 35px;
  text-align: center;
  margin: 20px 0;
  line-height: 38px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container h1 {
  text-align: center;
  font-size: 22px;
}
.amount {
  font-size: 29px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
}
.successIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.description {
  color: var(--gray-color);
}
.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
}
.buttonsContainer > * {
  flex: 1 !important;
  width: 100% !important;
}

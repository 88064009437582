.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeb1bf80 !important;
  font-size: 11px;
  margin-right: 20px;
}

.languageSelectorContainer {
  display: flex;
  align-items: center;
  gap: 3px;
  height: 18px;
  transition: color 0.3s;
  border: none;
  color: #aeb1bf;
  background: none;
}

.languageSelectorContainer:hover {
  color: var(--primary-color);
  transition: color 0.3s;
  cursor: pointer;
}
.languageSelectorContainer:hover .divider {
  background-color: var(--primary-color);
  transition: color 0.37s;
}

.divider {
  height: 16.5px;
  width: 1px;
  background-color: #aeb1bf;
  margin: 0 10px;
  border-radius: 20px;
  align-self: center;
  transition: color 0.3s;
}

.themeToggleContainer {
  transition: color 0.4s;
}
.themeToggleContainer:hover {
  transition: color 0.4s;
  color: var(--primary-color);
  cursor: pointer;
}

/* POPPER */
.popperContainer {
  width: 210px;
  color: var(--gray-color);
  background-color: var(--secondary-color);
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
  /* box-shadow: 0px 2px 20px rgba(0,0,0,.4); */
  font-size: 12px;
  margin-top: 20px;
  transform: translateY(19px);
}
.tabsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: 5px;
}
.tab {
  background: none;
  border: none;
  color: var(--gray-color);
  transition: 0.3s ease;
  font-weight: 300;
  padding: 0 0 18px 0;
}
.tab:hover {
  color: var(--primary-color);
  transition: 0.3s ease;
}
.selectedTab {
  border-bottom: 2px var(--primary-color) solid;
  color: var(--primary-color);
}

.languageCurrencySelectorRowsContainer {
  display: flex;
  flex-direction: column;
}
.languageCurrencyRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  color: var(--gray-color);
  margin: 3px 0;
  padding: 5px;
  border-radius: 4px;
}
.languageCurrencyRow:hover {
  background-color: #8080801c;
}
.languageCurrencyRow > span:first-child {
  margin-right: auto;
}
.selectedRow,
.selectedRow:hover {
  color: #2b3144;
  background-color: var(--primary-color);
}

.selectedIconContainer {
  height: 15.5px;
  width: 15.5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  color: var(--primary-color);
}

/* --------------- RESPONSIVENESS --------------- */
/* ---------------------------------------------- */
/* @media (max-width: 980px) {
    .mainContainer {
        display: none;
    }
} */
/* ---------------------------------------------- */
/* ---------------------------------------------- */

.row {
  --horizontal-spacing: 20px;
  --vertical-spacing: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--horizontal-spacing);
}
.row > * {
  flex: 1;
}

@media (max-width: 675px) {
  .row {
    flex-direction: column;
    gap: var(--vertical-spacing);
    margin-bottom: var(--vertical-spacing);
  }
  .row > * {
    width: 100%;
  }
}

.pageContainer {
  /* max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto; */
  overflow-y: hidden;
}

.homeContainer {
  background: #00191e url("../../assets/geo-terrain.png");
  background-size: cover;
  margin: 40px 0;
  border-radius: 20px;
  text-align: center;
  padding: 70px;
  margin: 40px;
  color: white;
}
.homeContainer h1 {
  font-size: 58px;
  font-weight: 600;
}
.homeContainer > span {
  font-size: 18px;
}
.homeStatsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 67%;
  margin: 75px auto 0 auto;
}

.homeStatsContainer > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.homeStatsContainer > div > span:first-child {
  font-size: 38px;
  color: var(--primary-color);
  font-weight: bold;
}
.homeStatsContainer > div > span:last-child {
  font-size: 20px;
}

/* TABS CONTAINER */
.tabsContainer {
  display: flex;
  align-items: center;
  border-bottom: 1.4px var(--border-color) solid;
  gap: 27px;
  margin: 0 5%;
}

/* GRID CONTAINER */
.cardsContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(350px, 1fr));
  /* grid-template-rows: repeat(2, auto); */
  row-gap: 73px;
  padding: 35px 0;
}

@media (max-width: 1250px) {
  .homeContainer {
    padding: 70px;
  }
  .homeStatsContainer {
    margin: 20px auto 0 auto;
    width: 100%;
  }
  .cardsContainer {
    grid-template-columns: repeat(2, minmax(350px, 1fr));
  }
}
@media (max-width: 790px) {
  .cardsContainer {
    grid-template-columns: repeat(1, minmax(350px, 1fr));
  }
}
@media (max-width: 950px) {
  .homeStatsContainer {
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    margin: 50px auto;
    gap: 50px;
  }
}

@media (max-width: 670px) {
  .homeContainer {
    padding: 60px 10px;
  }
  .homeContainer h1 {
    font-size: 45px;
  }
}

.container {
  margin: 10px 0;
  width: fit-content;
}
.input {
  display: none;
}

.label {
  /* color: var(--gray-color); */
  font-size: 13px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  user-select: none;
}

.checkbox {
  background-color: var(--primary-color);
  border-radius: 3.5px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.outlineCheckbox {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px var(--border-color) solid;
}

.errorSpan {
  font-size: 11px;
  color: var(--negative-color);
}

#nprogress .bar {
  margin-top: 95px;
  background: var(--primary-color);
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--primary-color), 0 0 5px var(--primary-color);
}

#nprogress .spinner-icon {
  border-top-color: var(--primary-color);
  border-left-color: var(--primary-color);
}

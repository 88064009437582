.title {
  font-size: 33px;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  line-height: 38px;
  white-space: nowrap;
}

.otpTitle {
  font-size: 33px;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  line-height: 38px;
  white-space: nowrap;
  font-weight: 500;
}

.otpDescription {
  text-align: center;
  font-size: 14px;
}

.container {
  width: 100%;
}

.flexContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px var(--border-color) solid;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.cardTitle {
  font-size: 13px;
  font-weight: bold;
}

.groupByContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.groupByContainer > span {
  font-size: 12px;
}

.dropdownContainer {
  background-color: var(--background-color-inverted);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  padding: 5px;
}
.precisionButton {
  background: none;
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  width: 100%;
}
.selectedOption {
  background-color: var(--primary-color-opacity);
}

.searchMarketPairContainer {
    border: 1px #ccced926 solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 7px;
    width: 230px;
}
.searchMarketPairContainerLight {
    border: 1px #ccced9 solid;
}
.searchMarketPairContainer input {
    flex: 1;
    background: none;
    border: none;
    outline: none;
    transition: all .4s ease;
}
.searchMarketPairContainer input::placeholder {
    color: var(--gray-color);
}
.searchMarketPairIcon {
    margin: 0 4.5px;
    transition: all .4s ease;
}
.searchMarketPairContainer:focus-within {
    border-color: var(--primary-color);
    transition: all .4s ease;
}
.searchMarketPairContainer:not(:focus-within) {
    transition: all .4s ease;
}
.searchMarketPairContainer:focus-within .searchMarketPairIcon {
    color: var(--primary-color);
    transform: scale(1.4);
    transition: all .4s ease;
}
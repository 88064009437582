.selected-flag .open {
  background: none !important;
}

.react-tel-input .flag-dropdown.open {
  background: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}
.react-tel-input .country-list > .country {
  border-radius: 5px !important;
  margin: 3px 0;
}
.react-tel-input .country-list .country.highlight {
  background-color: var(--primary-color-opacity);
  color: var(--primary-color);
}

.flag {
  transform: scale(1.3);
}

/* KYC FORM */
.formContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 30px;
  margin: 40px 18.5%;
}

.formSectionTitle {
  font-size: 17px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.formRowContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  margin: 20px 0;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 700px) {
  .formContainer {
    margin: 40px 5%;
  }
}
@media (max-width: 1025px) {
  .formRowContainer {
    flex-direction: column;
    gap: 0;
  }
  .formRowContainer > * {
    width: 100%;
  }
  .checkboxInputContainer > * {
    margin: 10px 0;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.jumbotronContainer {
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  margin: 45px auto;
  padding: 50px 10%;
  height: 580px;
  width: 97vw;
  margin: 0 auto;
}

.verticalDivider {
  width: 0.4px;
  height: 200px;
  background-color: var(--gray-color);
  margin: auto 0;
}

/* LEFT SECTION */
.leftContainer {
  height: 100%;
  flex: 0.37;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leftContainer > h3 {
  font-size: 38.5px;
  font-weight: 600;
  margin: 15px 0 37px 0;
  padding: auto 0;
}

.secondaryTitle {
  color: var(--primary-color);
}

.jumbotronDescription {
  color: var(--gray-color);
}

/* RIGHT SECTION */
.rightContainer {
  height: 100%;
  flex: 0.37;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.featureContainer {
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
}
.featureContainer > *:first-child {
  /* width: 30px; */
  /* height: 30px; */
}
.featureContainer > *:last-child {
  display: flex;
  flex-direction: column;
}
.featureContainer > *:last-child > span:first-child {
  font-size: 21px;
}
.featureContainer > *:last-child > span:last-child {
  color: var(--gray-color);
}

@media (max-width: 1055px) {
  .jumbotronContainer {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 50px 0px;
  }
  .jumbotronContainer * {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .verticalDivider {
    display: none;
  }
  .leftContainer {
    max-width: 500px;
    padding: 0 20px;
  }
  .rightContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 35px;
    flex-wrap: wrap;
  }
  .rightContainer > * {
    transform: translateX(-13px);
  }
}

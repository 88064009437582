.mainContainer {
  background-color: var(--secondary-color);
  height: fit-content;
  /* color: white; */
  padding: 90px calc(var(--horizontal-page-gap) / 2) 0 calc(var(--horizontal-page-gap) / 2);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 60px;
}
.mainContainer * {
  z-index: 0 !important;
}

.mediaSection {
  width: 220px;
  margin-bottom: 100px;
  margin-right: 100px;
}

.mediaSection img {
  width: 140px;
  border-right: 0.6px #aeb1bf80 solid;
  padding-right: 20px;
  margin-left: 20px;
}

.mediaSection p {
  color: #6c7080;
  font-size: 13.5px;
  margin: 30px 0 45px 0;
}

.mediaLinksContainer {
  width: 100%;
  display: flex;
  /* padding: 0 20px; */
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  flex-wrap: nowrap;
}

.bottomContainer {
  width: 100%;
  border-top: #6c7080a3 0.6px solid;
  padding: 27px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  /* flex-wrap: wrap; */
}

.bottomLeftContainer {
  color: #6c7080;
  font-size: 13.5px;
  display: flex;
  align-items: center;
}

.bottomRightContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.bottomMidContainer {
  margin: 0 10px;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-left: 75px;
}

.bottomMidContainer > * {
  border-left: #6c7080 0.7px solid;
  padding-left: 10px;
}

.appContainer {
  background-color: black;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.appContainer:hover {
  background-color: var(--primary-color);
  color: black;
  cursor: pointer;
}
.appContainer:hover .appleStoreIcon {
  border-color: black;
}

.appleStoreIcon {
  border: 1px white solid;
  padding: 2px;
  border-radius: 3px;
}

.downloadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.downloadContainer span:first-child {
  font-size: 13.5px;
}
.downloadContainer span:last-child {
  font-size: 13.5px;
}

@media (max-width: 749px) {
  .bottomContainer {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .bottomMidContainer {
    margin: 0;
    justify-content: space-between;
    width: 90%;
  }
  .bottomMidContainer * {
    font-size: 13.5px !important;
  }
}

/* SCROLL TO TOP */
.srollTopButtonContainer {
  color: var(--gray-color);
  border: var(--gray-color) 1px solid;
  border-radius: 5px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -52px;
  transition: all ease 0.4s;
}

.srollTopButtonContainer:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all ease 0.2s;
  cursor: pointer;
  border-bottom: 2px var(--primary-color) solid;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */

/* Footer mobile payments section */
.mobilePaymentContainer {
  color: var(--gray-color);
  text-align: center;
  display: none;
}
.footerPaymentsIonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.paymentCard {
  background-color: var(--background-color);
  width: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
}

.mediaLinksContainerMobile {
  display: none;
}

@media (max-width: 1100px) {
  .bottomMidContainer {
    margin-left: 20px;
  }
}
@media (max-width: 1100px) {
  /* .bottomRightContainer {
    transform: scale(0.7);
    margin-right: -40px;
  } */
}

@media (max-width: 495px) {
  .mainContainer {
    justify-content: flex-start;
  }
  .mainContainer > * {
    min-width: 120px;
    margin-left: auto;
  }
}

@media (max-width: 500px) {
  .mediaLinksContainer {
    display: none;
  }
  .bottomRightContainer {
    display: none;
  }
  .mediaLinksContainerMobile {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottomContainer > *:nth-child(5) {
    display: none !important;
  }
  .mobilePaymentContainer {
    display: block;
  }
  .mobilePaymentContainer > *:first-child {
    text-align: left;
    margin-left: -15vw;
  }
  .mediaLinksContainer {
    padding: 0 20px;
    gap: unset;
    justify-content: space-between;
  }
  .mediaSection {
    margin: 0 auto 120px 0;
    width: 100%;
    text-align: left;
  }
  .mediaSection img {
    margin-right: auto;
    border: none;
    width: 50%;
  }
  .mediaSection p {
    display: none;
  }
  .bottomContainer {
    border-top: none;
  }
  .mobilePaymentContainer {
    border-bottom: 1px var(--border-color) solid;
    padding-bottom: 40px;
  }
  .bottomMidContainer {
    margin-left: unset;
    gap: 20px;
  }

  .srollTopButtonContainer {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.container {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 10px;
}

.price {
  color: var(--gray-color);
  font-size: 12px;
  margin-left: auto;
}

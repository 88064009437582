.MuiPagination-root {
  width: fit-content;
  color: var(--text-color) !important;
}
.MuiButtonBase-root {
  color: rgba(211, 211, 211, 0.538);
}
.MuiPaginationItem-ellipsis {
  color: rgba(211, 211, 211, 0.616) !important;
}

.MuiPaginationItem-root {
  font-family: "Outfit", "Overpass", sans-serif !important;
  border: none !important;
  color: var(--gray-color) !important;
  font-size: 12.5px !important;
}

.MuiPagination-root .Mui-selected {
  /* background-color: rgba(25, 185, 210, 0.12) !important; */
  background: transparent !important;
  /* border: 1px solid rgba(0, 242, 255, 0.616) !important; */
  color: var(--primary-color) !important;
}

.MuiPaginationItem-previousNext {
  /* border: 1px solid rgba(0, 242, 255, 0.616) !important; */
}

.dark {
  background-color: black; //original 1b1d2a then #10131c
  color: white;
}
.dark header {
  background: #171d29; //original #131722
}
.dark .light-bb,
.dark .input-group-text,
.dark .nav-tabs {
  border: none;
  border-bottom: 1px solid #2a2e39;
}
.dark .market-trade .nav-tabs {
  border: none;
}
.dark header nav a {
  color: #ffffff !important;
}
.dark a:hover {
  // color: var(--primary-color) !important; //84e9eb
  color: var(--primary-color); //84e9eb
}
.dark .nav-tabs .nav-link.active {
  background: transparent;
}
.dark header nav a.btn:hover {
  color: #84e9eb;
}
.dark header nav .nav-item.active a {
  color: #84e9eb;
}
.dark .market-pairs {
  border: 1px solid #2a2e39;
  background: #171d29;
  color: #ffffff;
}
.dark tr {
  // background: #171d29;
  background: transparent;
}

// .dark .market-pairs .table td,
// .dark .market-pairs .table th {
//   border-top: 1px solid #2a2e39;
// }
// .dark .market-history .table td,
// .dark .market-history .table th {
//   border-top: 1px solid #2a2e39;
// }
// .dark .order-book thead tr {
//   border-bottom: 1px solid #2a2e39;
// }
// .dark .order-book,
// .dark .market-history,
// .dark .market-news {
//   border: 1px solid #2a2e39;
// }
// .dark tbody tr:hover {
//   background: #2a2e39;
// }
// .dark .form-control::-webkit-input-placeholder {
//   /* Chrome/Opera/Safari */
//   color: #69727a;
// }
// .dark .form-control::-moz-placeholder {
//   /* Firefox 19+ */
//   color: #69727a;
// }
// .dark .form-control:-ms-input-placeholder {
//   /* IE 10+ */
//   color: #69727a;
// }
// .dark .form-control:-moz-placeholder {
//   /* Firefox 18- */
//   color: #69727a;
// }
// .dark tbody tr {
//   color: #ffffff;
// }
// .dark thead th,
// .dark .market-pairs .nav-link {
//   color: #4f5966;
// }
// .dark .market-pairs .nav-link.active,
// .dark #STAR i {
//   color: #ffffff;
// }
// .dark .green {
//   color: #26de81;
// }
// .dark .red {
//   color: #ff231f;
// }
// .dark .market-pairs .nav {
//   background: #1c2030;
// }
// .dark .market-pairs input,
// .dark .market-pairs span {
//   border-color: #2a2e39;
//   background: #2a2e39;
// }
// .dark .market-pairs input {
//   color: #ffffff;
// }
// .dark .heading {
//   background: #1c2030;
//   color: #ffffff;
// }
// .dark .ob-heading tr {
//   border-top: 1px solid #2a2e39 !important;
//   border-bottom: 1px solid #2a2e39 !important;
// }
// .dark .ob-heading tr:hover {
//   background: #131722;
//   cursor: default;
// }
// .dark .ob-heading span {
//   color: #4f5966;
// }
// .dark .dropdown-menu {
//   background: #171d29;
//   box-shadow: 0 0 10px 0 #131722;
//   border: 1px solid #2a2e39;
// }
// .dark .dropdown-item:focus,
// .dark .dropdown-item:hover {
//   background-color: #2a2e39;
//   color: #ffffff;
// }
// .dark .market-history .nav {
//   background: #1c2030;
// }
// .dark .market-history .nav-link.active {
//   color: #ffffff;
// }
// .dark .market-history .nav-link {
//   color: #4f5966;
// }
// .dark .red-bg-80:after,
// .dark .red-bg-60:after,
// .dark .red-bg-40:after,
// .dark .red-bg-20:after,
// .dark .red-bg-10:after,
// .dark .red-bg-8:after,
// .dark .red-bg-5:after,
// .dark .red-bg:after {
//   background: #ff231f63;
// }
// .dark .market-news {
//   background: #131722;
// }
// .dark .market-news li {
//   border-bottom: 1px solid #2a2e39;
// }
// .dark .market-news li:hover {
//   background: #2a2e39;
// }
// .dark .market-news li strong,
// .dark .market-news li a {
//   color: #c5cbce;
// }
// .dark .market-order {
//   background: #171d29;
// }
// .dark .no-data i,
// .dark .no-data {
//   color: #2a2e39;
// }
// .dark .market-trade {
//   border: 1px solid #2a2e39;
//   background: #171d29;
// }
// .dark .market-trade .logged-out {
//   color: #f0f0f0;
// }
// .dark .market-trade .nav .nav-item .nav-link.active {
//   background: transparent;
//   color: #ffffff;
// }
// .dark .market-trade .nav .nav-item .nav-link {
//   color: #4f5966;
// }
// .dark .market-trade p {
//   color: #c5cbce;
// }
// .dark .market-trade-list li a {
//   background: #2a2e39;
//   color: #ffffff;
// }
// .dark .market-trade input {
//   border: 1px solid #2a2e39;
//   background: #171d29;
//   color: #ffffff;
// }
// .dark .market-trade .input-group-text {
//   background: #1d2536;
//   color: #ffffff;
//   border-color: #2a2e39;
// }
// .dark .market-trade-buy .market-trade-list li a:hover {
//   background: #26de81;
//   color: #fff;
// }
// .dark .market-trade-sell .market-trade-list li a:hover {
//   background: #ff231f;
//   color: #fff;
// }
// .dark #darkDepthChart {
//   background: #171d29;
// }
// .dark .markets {
//   background: #171d29;
// }
// .dark .table td,
// .dark table th {
//   // border-top: 1px solid #2a2e39;
//   font-size: 12px;
// }
// .dark table td {
//   font-size: 12px;
//   font-family: "Outfit", "Overpass" !important;
// }
// .dark .markets-pair-list .nav {
//   background: #1c2030;
// }
// .dark .markets-pair-list .nav-link.active,
// .dark #STAR i {
//   color: #ffffff;
// }
// .dark thead th,
// .dark .markets-pair-list .nav-link {
//   color: #4f5966;
// }
// .dark .markets-pair-list .load-more {
//   border: 1px solid #2a2e39;
//   color: #ffffff;
// }
// .dark .markets-pair-list .load-more:hover {
//   color: #fff;
//   border: 1px solid #84e9eb;
// }
// .dark .markets-content h2,
// .dark .markets-content p {
//   color: #ffffff;
// }
// .dark .markets-content span.green,
// .dark .markets-content span.red {
//   color: #fff;
// }
// .dark .market-trade-sell {
//   border-left: 1px solid #2a2e39;
// }
// .dark .markets-container {
//   background: #1e222d;
// }
// .dark .sign-up-form h3 {
//   border-top: 0;
//   margin-top: 0;
//   padding-top: 15px;
// }
// .dark header nav .nav-item.active a.btn:hover {
//   color: #fff;
// }

// .dark .custom-control-input:checked ~ .custom-control-label::before {
//   color: #fff;
//   border-color: #84e9eb;
//   background-color: #84e9eb;
// }

// .dark .dropdown-menu::before {
//   background: #171d29;
//   border-top: 1px solid #2a2e39;
//   border-left: 1px solid #2a2e39;
// }

// .dark .dropdown-menu p {
//   color: #ffffff;
// }

// .dark .dropdown-header {
//   border-bottom: 1px solid #2a2e39;
// }
// .dark .dropdown-footer {
//   border-top: 1px solid #2a2e39;
// }

// .dark .header-img-icon .profile-nav .nav-item .nav-link:hover {
//   background-color: #2a2e39;
//   color: #ffffff;
// }

// .dark .form-control,
// .dark .settings-profile select {
//   // background-color: #2a2e39;
//   // border: 1px solid #2a2e39 !important;
//   // color: #ffffff;
// }
// .dark .form-access form {
//   padding: 30px;
//   border-radius: 5px;
//   min-width: 350px;
//   box-shadow: 0 0 10px 0 #131722;
//   border: 1px solid #2a2e39;
// }
// .dark .form-access h2,
// .dark .form-access {
//   color: #ffffff;
// }

// .dark .form-access .custom-control-label::before {
//   border: #2a2e39 solid 1px;
//   background-color: #2a2e39;
// }

// .dark .error-page a {
//   border: 1px solid #2a2e39;
//   color: #ffffff;
// }
// .dark .error-page a:hover {
//   border: 1px solid #84e9eb;
//   background: #84e9eb;
//   color: #ffffff;
// }

// .dark .error-page h2,
// .dark .error-page p {
//   color: #c5cbce;
// }

// .dark .wallet .nav-pills a,
// .dark .wallet-history {
//   border-top: 1px solid #2a2e39;
// }
// .dark .wallet .nav {
//   background: #171d29; // before 1e222d
// }
// .dark .wallet h2,
// .dark .wallet h3,
// .dark .wallet h4,
// .dark .wallet p {
//   color: #ffffff !important;
// }
// .dark .wallet button.green,
// .dark .wallet button.red,
// .dark .wallet .nav-pills a.active h2,
// .dark .wallet .nav-pills a.active h3,
// .dark .wallet .nav-pills a.active p {
//   color: #ffffff;
// }

// .dark .wallet .tab-content li:first-child {
//   border-bottom: 1px solid #2a2e39;
// }
// .dark .wallet-history tr {
//   background: #171d29;
// }
// .dark .wallet-address input,
// .dark .wallet-address input:focus {
//   border: 1px solid #2a2e39;
//   background: #2a2e39;
//   box-shadow: none;
//   color: #ffffff;
// }
// .dark .depth-chart-inner {
//   border: 1px solid #2a2e39;
// }

// .dark .markets-capital-item {
//   box-shadow: 0 0 10px 0 #131722;
//   border: 1px solid #2a2e39;
// }

// .dark .markets-capital-item h2,
// .dark .markets-capital-item h4 {
//   color: #fff;
// }
// .dark .settings .card {
//   background: #171d29;
// }

// .dark .settings .card-title {
//   color: #fff;
// }

// .dark .settings-nav {
//   background: #171d29;
// }

// .dark .settings-nav .nav-link {
//   color: #ffffff;
// }

// .dark .settings-notification li {
//   border-bottom: 1px solid #2a2e39;
// }
// .dark .settings-notification li:last-child {
//   border-bottom: 0;
// }
// .dark .settings-notification p {
//   color: #ffffff;
// }
// .dark .settings-notification span {
//   color: #4f5966;
// }

// .dark .settings label {
//   color: #fff;
// }
// .dark .settings .custom-file-label {
//   background: #2a2e39;
//   border: 1px solid #2a2e39;
//   color: #616d75;
// }

// .dark .settings-profile input[type='submit'] {
//   border: 1px solid #84e9eb;
// }
// .dark .market-carousel-item {
//   border: 1px solid #2a2e39;
// }
// .dark .market-carousel-item {
//   color: #fff;
// }
// .dark .market-carousel-item .btn.buy:hover,
// .dark .market-carousel-item .btn.sell:hover {
//   color: #fff;
//   border-color: #fff;
// }
// .dark .news-details,
// .dark .page-content {
//   color: #fff;
// }

.cardContainer {
  background-color: black;
  border-radius: 8px;
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 300px; */
  gap: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 12px;
  /* width: 60%; */
}
.coinNameContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.coinNameContainer img {
  width: 30px;
  height: 30px;
}
.coinNameContainer span {
  /* padding-right: 20%; */
  white-space: nowrap;
}

.detailsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-right: 5px;
}

.changePercentageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.changePercentageContainer span {
  margin-right: 1px;
}
.changePercentageContainer img {
  width: 12px;
}

.positiveChange {
  color: var(--positive-color);
}

.negativeChange {
  color: var(--negative-color);
}

.coinPrice {
  white-space: nowrap;
  font-weight: bold;
}

.graphContainer {
  flex: 1;
  margin-left: 10px;
}

.approxSymbol {
  color: var(--gray-color);
  margin-right: 5px;
  font-size: 15px;
}

.headerContainer {
  text-align: center;
  margin-top: 105px;
  margin-bottom: 120px;
}

.headerContainer > span {
  color: var(--primary-color);
  font-size: 30px;
}
.headerContainer > h1 {
  font-size: 80px;
}
.headerContainer > p {
  color: var(--gray-color);
  font-size: 16px;
}

/* -- SMALL HEADER --  */
.smallContainer > span {
  font-size: 18px;
}
.smallContainer > h1 {
  font-size: 36px;
}
.smallContainer > p {
  font-size: 15px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1120px) {
  .pageContainer {
    padding: 0 8vw;
  }
}
@media (max-width: 699px) {
  .headerContainer > h1 {
    font-size: 45px;
  }
  .headerContainer > span {
    font-size: 25px;
  }
  .headerContainer > p {
    font-size: 15px;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.title {
  font-size: 21px;
  text-align: center;
  margin: 20px 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.termsContainer {
  opacity: 0.75;
  font-size: 13.25px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
.buttonsContainer > * {
  flex: 1;
}

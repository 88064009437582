.bodyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  position: relative;
}

.title {
  font-size: 19px;
}

.description {
  font-size: 13px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.pageHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 80px 0;
}

.pageHeaderLeft {
}

.pageHeaderRight {
}

.pageHeaderIndicatorText {
  color: var(--gray-color);
}

.illustration {
  object-fit: contain;
  width: 400px;
}

@media (max-width: 890px) {
  .pageHeaderContainer {
    flex-direction: column;
    gap: 80px;
  }
}

.mainContainer {
}

.uploadContainer {
  border: 1.5px var(--border-color) dashed;
  border-radius: 10px;
  max-width: 300px;
  margin: 10px 0;
  padding: 20px;
}
.uploadIconContainer {
  border-radius: 5px;
  background-color: var(--primary-color-opacity);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadTitle {
  font-weight: 400;
  font-size: 20px;
  margin: 20px 0;
}
.allowedExtensionsText {
  color: var(--gray-color);
}
.limitText {
  color: var(--gray-color);
}

.label {
  color: var(--gray-color);
  font-size: 11px;
}
.required {
  color: var(--primary-color);
}

/* FILE VIEW */
.listItemContainer {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.listItemContainer > *:last-child {
  margin: 20px 0;
  width: 26px;
  height: 26px;
}

.listImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.listDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* file name */
.listDetailsContainer > span:first-child {
  font-weight: 200;
  font-size: 12px;
}

/* file size */
.listDetailsContainer > span:last-child {
  font-weight: 200;
  color: var(--gray-color);
  font-style: italic;
}

/* error span */
.errorSpan {
  color: var(--negative-color);
  font-size: 11px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  border-radius: 7.5px;
  padding: 10px;
  cursor: pointer;
  width: 320px;
}
.container:hover {
  background-color: var(--background-color);
}
.nameDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name {
  font-weight: 500;
  font-size: 15.5px;
  color: var(--text-color);
}

.description {
  color: var(--gray-color);
  font-size: 13px;
}

/* ITEM ARROW STYLING */
.container > *:last-child {
  opacity: 0;
  transform: translateX(-5px) rotate(180deg) !important;
  transition: all 0.2s linear;
  margin-left: auto;
}

.container:hover > *:last-child {
  opacity: 1;
  transform: translateX(0) rotate(180deg) !important;
}

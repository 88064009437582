.isSelected {  
    border-left: 2.5px var(--primary-color) solid;
    background-color: #ffffff11;
}

.rowContainer {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rowContainer:hover {
    background-color: #d3d3d315;
    cursor: pointer;
}

.leftSectionRow {
    flex: .55;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
}
.leftSectionRow img {
    height: 23px;
    margin: 0 10px 0 7px;
}
.leftSectionRow > div {
    display: flex;
    flex-direction: column;
}

.rightSectionRow {
    flex: .35;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}
.rightSectionRow > span:first-child {
    font-size: 12px;
}
.rightSectionRow > span:last-child {
    font-size: 12px;
}
// MAIN
.light {
  background-color: white;
  color: #2b3144;
}

// LINKS
.light .headerLink {
  //header links
  color: #2b3144;
}
.light a:hover {
  color: var(--primary-color) !important; //48726e
}

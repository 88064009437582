.button {
  text-align: left;
  background: none;
  border: none;
  padding: 5px;
  margin: 2.5px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.15s ease;
}
.button:hover {
  color: var(--primary-color);
}

.selected {
  color: black;
  background-color: var(--primary-color);
}
.selected:hover {
  color: black;
}

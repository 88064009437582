/* QR modal */
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.destinationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.destinationContainer > span {
  color: #6c7080;
}
.destinationContainer > div {
  border: 1px var(--border-color) solid;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: fit-content;
}
.destinationContainer > div > span {
  /* flex: 1; */
  /* margin-right: auto; */
  margin: 0 7px 0 5px;
  font-size: 10px;
  word-break: break-all;
}

.disclaimerContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 35px;
}
.disclaimerContainer > div {
  height: 0.7px;
  width: 95%;
  background-color: var(--border-color);
}
.disclaimerContainer > span:last-child {
  color: #6c7080;
  font-size: 11px;
}

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.pageContainer {
  background-color: black;
  color: white;
  padding: 50px 125px;
  display: flex;
  gap: 20px;
  /* height: 900px; */
}
.sideNavContainer {
  width: 270px;
  background-color: var(--secondary-color);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.mainContainer {
  flex: 1;
  height: 100%;
}

.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titlesContainer {
  display: flex;
  flex-direction: column;
}
.titlesContainer > *:last-child {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 300;
}

.ordersCard {
  background-color: var(--secondary-color);
  border-radius: 10px;
  /* width: 78.5%; */
  padding: 25px;
}
.tabsContainer {
  display: flex;
  align-items: center;
  height: 70px;
  gap: 20px;
  border-bottom: 1px var(--border-color) solid;
}
.tabsContainer > * {
  color: var(--gray-color);
  height: 100%;
  margin: 0;
}

/* filter/date */
.filterContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  transition: color 0.3s ease;
}
.filterContainer:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.filterContainer > *:last-child {
  animation: arrowDown 0.3s ease forwards;
}
.filterContainer:hover > *:last-child {
  animation: arrowUp 0.3s ease forwards;
}
@keyframes arrowUp {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes arrowDown {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0);
  }
}

.orderHistoryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0;
}
.orderHistoryRow > span:nth-child(1) {
  flex: 0.2;
  color: var(--gray-color);
  font-size: 11.35px;
}
.orderHistoryRow > span:nth-child(2) {
  flex: 0.15;
  display: flex;
  align-items: center;
  gap: 7px;
}
.orderHistoryRow > span:nth-child(3) {
  flex: 0.1;
}
.orderHistoryRow > span:nth-child(4) {
  flex: 0.15;
}
.orderHistoryRow > span:nth-child(5) {
  flex: 0.15;
}
.orderHistoryRow > span:nth-child(6) {
  flex: 0.15;
  color: var(--gray-color);
  font-size: 13px;
}

.orderHistoryRow > span:nth-child(2) > *:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
}
.orderHistoryRow > span:nth-child(2) > *:last-child > span:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

/* MUI DATAGRID COLUMNS */
.sideColumn {
  display: flex;
  align-items: center;
  gap: 7px;
}
.sideColumn > *:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
}
.sideColumn > *:last-child > span:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.mobileColumnHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
}
.mobileColumnHeader img {
  width: 40px;
}

@media (max-width: 820px) {
  .tabHeader h5 {
    display: none;
  }
  .tabHeader > *:last-child {
    width: 100%;
  }
  .titlesContainer > span {
    display: none;
  }
}
/*  TABLE ROWS */
.pairColumn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pairColumn img {
  width: 23px;
  height: 23px;
}
/* ---------------------------------------- */
/* ---------------------------------------- */

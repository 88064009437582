.card {
  border-radius: 10px;
  padding: 16.5px;
  background-color: var(--secondary-color);
  height: 1231px;
}
.NotFoundContainer {
  color: var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 35px;
  font-size: 17px;
}

.searchMarketPairIcon {
  margin: 0 4.5px;
  transition: all 0.4s ease;
}
.searchMarketPairContainer:focus-within {
  border-color: var(--primary-color);
  transition: all 0.4s ease;
}
.searchMarketPairContainer:not(:focus-within) {
  transition: all 0.4s ease;
}
.searchMarketPairContainer:focus-within .searchMarketPairIcon {
  color: var(--primary-color);
  transform: scale(1.2);
  transition: all 0.4s ease;
}

.marketPairTableHeader {
  border-bottom: 1px #ccced939 solid;
  padding: 5px;
  display: flex;
  color: var(--gray-color);
  font-size: 12px;
  margin: 10px 0 16.5px 0;
  padding-bottom: 13.25px;
}
.marketPairTableHeader > span:first-child {
  flex: 0.65;
}
.marketPairTableHeader > span:last-child {
  flex: 0.35;
}

.rowsContainer {
  /* border: 2px red solid; */
  height: calc(100% - 105px);
  overflow-y: scroll;
}

/* SHIMMER EFFECTS */
.loadingRowContainer {
  padding: 11.5px 3px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.loadingRowContainer > div:first-child {
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 10px;
}
.loadingRowContainer > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 0.5;
}

.tabContainer {
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.tabContainer h5 {
  margin-bottom: 45px;
}

.authenticatorRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 32.5px 0;
}
.authenticatorRow img {
  width: 34px;
}
.authenticatorRowLeft {
  display: flex;
  align-items: flex-start;
  margin-right: auto;
  gap: 10px;
}
.authenticatorRowLeft > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.authTitle {
  font-size: 13px;
}
.authDescription {
  color: #9295a6;
  font-size: 10px;
}
.authenticatorRow > *:last-child {
  width: fit-content;
  align-self: center;
}

.activatedAuth {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 300;
  color: var(--success-color);
  font-size: 10.5px;
  margin-top: 5px;
}
.disabledAuth {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 300;
  color: var(--danger-color);
  font-size: 10.5px;
  margin-top: 5px;
}
.disabledAuth > *:last-child {
  transform: translateY(0.35px);
}

.disabledAuthButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smallLabel {
  color: #9295a6;
  font-size: 12px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabContainer {
    width: 90vw;
  }
  .authenticatorRowLeft {
    align-items: center;
  }
  .authenticatorRowLeft > div > span:last-child {
    display: none;
  }
}
@media (max-width: 550px) {
  .authDescription {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

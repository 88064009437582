.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 45px;
}

.linkContainer {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 55px 0;
  gap: 10px;
}

.iconContainer {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.noJobsContainer {
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  border: 1px var(--border-color) solid;
  padding: 65px 50px 30px 50px;
  margin: 40px auto;
  position: relative;
}
.noJobsContainer p {
  font-weight: 300;
  font-size: 19px;
  text-align: center;
}
.noJobsIconContainer {
  height: 82px;
  width: 102px;
  padding: 0 14px;
  position: absolute;
  top: -43px;
  left: calc(50% - 40.5px);
  background-color: var(--background-color);
}
.noJobsIconContainer > * {
  width: 100%;
  height: 100%;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1030px) {
  .container {
    gap: 20px;
  }
}
@media (max-width: 900px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 677px) {
  .container {
    grid-template-columns: 1fr;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.title {
  font-size: 22px;
  font-weight: 600;
}

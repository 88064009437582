.container {
  background-color: var(--primary-color-opacity);
  padding: 10px 20px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: var(--primary-color);
}

.rowContainer {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 3px 0;
  margin: 2px 0;
  height: 24px;
  cursor: default;
}
.rowContainer:hover {
  background-color: var(--border-color);
}
.rowContainer > div {
  flex: 0.33;
}
.rowContainer > div:nth-child(2) {
  text-align: center;
}
.rowContainer > div:last-child {
  text-align: right;
}

.rowBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all ease 0.5s;
  /* width: 50px; */
}

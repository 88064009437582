.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.tabHeader {
  margin-bottom: 20px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
}

/* left section */
.tabHeader > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.TitlesContainer {
  display: flex;
  flex-direction: column;
}
.subTitle {
  font-size: 14px;
  color: var(--gray-color);
  transform: translateY(-5px);
  font-weight: 300;
}

.tabsContainer {
  margin: 30px 0 0 0;
  display: flex;
  align-items: center;
  height: 70px;
  gap: 20px;
  border-bottom: 1px var(--border-color) solid;
}
.tabsContainer > * {
  color: var(--gray-color);
  height: 100%;
  margin: 0;
}
/* wallet balance text */
.tabHeader > div:first-child > div:nth-child(2) {
  color: #6c7080;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
}
/* wallet amount / hide price container */
.tabHeader > div:first-child > div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tabHeader > div:first-child > div:nth-child(3) > span:first-child {
  font-size: 24px;
  font-weight: 600;
}
.tabHeader > div:first-child > div:nth-child(3) > button {
  color: #6c7080;
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 12px;
}

/* right section */
.tabHeader > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}
.tabHeader > div:last-child > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background-color: black;
  height: fit-content;
  padding: 10px;
  border-radius: 5px;
}

/* rows */
.tabHeader > div:last-child > div:last-child > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
/* left section span */
.tabHeader > div:last-child > div:last-child > div > span:first-child {
  color: #6c7080;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}
/* right section span */
.tabHeader > div:last-child > div:last-child > div > span:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* withdraw/deposit container */
.tabHeader > div:last-child > div:first-child {
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}
.tabHeader > div:last-child > div:first-child > * * {
  height: 20px;
}
.accountBalanceTitle {
  display: flex;
  align-self: center;
  justify-content: center;
  color: var(--gray-color);
  gap: 10px;
  transform: translateY(22px);
}
/* ------------ MAIN TAB CONTAINER ------------ */
.walletsContainer {
  padding: 25px;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* MUI DATAGRID */
.coinNameColumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12.5px;
  gap: 10px;
}

.coinNameColumn img {
  width: 25px;
}

.buttonsContainer {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.hidePriceButton {
  background-color: 'var(--background-color)';
  border: none;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.hidePriceButtonMobile {
  width: 26.5px !important;
  height: 25px !important;
  padding: 0 !important;
  background-color: var(--background-color);
  display: none;
  border: none;
  border-radius: 5px;
}
.walletBalanceSpanMobile {
  font-weight: 400;
  color: var(--text-color);
  font-size: 18px;
  display: none;
}

.walletMobileColumn {
  border-bottom: 1px var(--gray-color) solid;
  height: 100%;
  width: 100%;
  padding: 10px 0;
}

.walletColumnHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
}
.walletColumnHeader img {
  height: 42px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
}
.row > span:first-child {
  color: var(--gray-color) !important;
  font-weight: 500;
}
.row > span:last-child {
  color: var(--text-color) !important;
  font-weight: 500;
}

.mobileButtonsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.walletBalanceContainer {
  margin: 15px 0;
}
.walletBalanceContainer > span:first-child {
  font-size: 23px;
}
/* .walletBalanceContainer > span:last-child {
  color: var(--gray-color);
  font-size: 15px;
} */

@media (max-width: 820px) {
  .accountBalanceTitle {
    transform: unset;
  }
  .hidePriceButtonMobile {
    display: block;
  }
  .walletBalanceSpanMobile {
    display: block;
  }
  .tabHeader > div:first-child > div:nth-child(3) {
    display: none;
  }
  .tabHeader {
    flex-direction: column;
  }
  .tableHeader h4 {
    display: none;
  }
  .tabHeader > div:first-child > div:nth-child(3) {
    justify-content: space-between;
  }
  .tabHeader > div:first-child > div:nth-child(3) > *:last-child {
    width: fit-content !important;
  }
  .tabHeader > div:last-child {
    margin-top: 30px;
    flex-direction: column-reverse;
  }
  .tabHeader > div:last-child > div:first-child {
    justify-content: space-between;
    gap: 0px;
  }
  .tabHeader > div:last-child > div:first-child > * {
    /* height: 55px !important; */
    font-size: 15px;
    border-radius: 10px;
    flex: 1;
  }
  .tabHeader > div:last-child > div:last-child {
    gap: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 10px 0;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.cardContainer {
    background-color: var(--secondary-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    text-align: center;
    height: 230px;
}

.cardContainer > h3 {
    text-transform: uppercase;
    font-size: 20px;
}

.cardContainer > span {
    color: var(--gray-color);
    font-size: 12px;
    margin-top: -15px;
}

.iconsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    /* padding-left: 38px; */
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 700px) {
    .cardContainer {
        width: 101vw !important;
        align-self: center;
    }
    .cardContainer {
        height: 280px;
    }
    .cardContainer > h3 {
        font-size: 24px;
    }
    .cardContainer > span {
        font-size: 17px;
    }
} 
/* ---------------------------------------- */
/* ---------------------------------------- */
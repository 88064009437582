.container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: fit-content !important;
  gap: 5px !important;
}

.coinImg {
  width: 25px;
}

.coinName {
  font-size: 18px;
}

.coinTag {
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 470px) {
  .coinName {
    font-size: 20px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

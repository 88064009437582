.tabContainer {
    padding: 25px;
    background-color: var(--secondary-color);
    border-radius: 10px;
}
.tabContainer h5 {
    margin-bottom: 33px;
}

.dateTimeColumn {
    flex: .3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.dateTimeColumn > span:last-child {
    color: var(--primary-color);
    font-size: 11px;
}

.modalDiv {
    border-top: 1px var(--border-color) solid;
    padding-top: 20px;
}


/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
    .tabContainer h5 {
        display: none;
    }
    .tabContainer {
        width: 90vw;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
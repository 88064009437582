.bodyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  position: relative;
}

.bodyContainer p {
  color: var(--gray-color);
  line-height: 20px;
}

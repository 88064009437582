.convertRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0;
}

.convertRow img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.convertInfoContainer {
  font-size: 11.5px !important;
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 25px 20px;
  margin: 25px 0;
  font-size: 10px;
}

.convertInfoContainer > div:last-child {
  font-weight: bold;
  color: var(--primary-color);
}

.infoRowContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.infoRowContainer > span:first-child {
  display: flex;
  align-items: center;
  gap: 10px;
}

.coinifyIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.infoIconDiv {
  /* border: 1.5px var(--primary-color) solid; */
  /* color: var(--primary-color); */
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

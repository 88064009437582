.headerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 25px;
    margin: 0 0 20px 0;
}

.title {

}
.tabContainer {
    padding: 35px;
}

.tabContainer h5 {

}

.notificationRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 45px 0;
}
.notificationRow img {
    width: 40px;
}
.notificationRowLeft {
    display: flex;
    align-items: center;
    /* margin-right: auto; */
    gap: 10px;
}
.notificationRowLeft > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.notificationRowLeft > div > span:first-child {
    font-size: 13px;
}
.notificationRowLeft > div > span:last-child {
    color: #9295A6;
    font-size: 10px;
}
.notificationRow > *:last-child {
    width: fit-content;
    align-self: center;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
    .tabContainer h5 {
        display: none;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
.cardContainer {
}

.thumbnailContainer {
  border-radius: 15px;
  background-color: whitesmoke;
  width: 100%;
  height: 220px;
}
.thumbnailContainer img {
}

.blogInfoContainer {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}
.blogInfoContainer > div {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  gap: 5px;
}

.cardContainer h2 {
  color: var(--primary-color);
}

.cardContainer p {
  font-size: 12px;
}

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 30px;
  margin: var(--gap) 0;
  height: 545px; /*620px original*/
}

.radioButtonsContainer {
  display: flex;
  gap: 25px;
}

.mainBuySellContainer {
  display: flex;
  margin-top: 25px;
  gap: 30px;
  height: 85%;
}
.buyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sellContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.balanceAvailable {
  color: var(--gray-color);
}

.feesInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9295a6;
  font-size: 11px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buySellTabletButtonsContainer {
  display: none;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1030px) {
  .sellContainer {
    display: none;
  }
  .buySellTabletButtonsContainer {
    display: flex;
    gap: 20px;
  }
  .buyButton {
    display: none;
  }
}

@media (max-width: 990px) {
  .card {
    margin-top: 0;
  }
  .radioButtonsContainer {
    gap: 0px;
    justify-content: space-between;
    margin: 0 -12.5px;
  }
  .radioButtonsContainer > *:last-child {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.link {
  color: #9295a6;
  transition: color 0.4s;
  font-size: 13.5px;
}

.link:hover {
  color: var(--primary-color);
  transition: color 0.4s;
  text-decoration: none;
}

.right {
  animation: 0.4s slideRightBack forwards;
}

.up {
  animation: 0.4s slideUpBack forwards;
}

.right:hover {
  animation: 0.4s slideRight forwards;
}

.up:hover {
  animation: 0.4s slideUp forwards;
}

@keyframes slideUp {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateY(-2px);
  }
}

@keyframes slideRight {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateX(4px);
  }
}

@keyframes slideRightBack {
  from {
    transform: translateX(4px);
  }
  to {
    transform: translateX(0) translateY(0);
  }
}

@keyframes slideUpBack {
  from {
    transform: translateY(-2px);
  }
  to {
    transform: translateX(0) translateY(0);
  }
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 500px) {
  .link svg {
    transform: scale(1.5) !important;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.tabContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 25px;
  /* width: 78.5%; */
}

.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titlesContainer {
  display: flex;
  flex-direction: column;
}
.titlesContainer > *:last-child {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 300;
}

.pageContainer {
  background-color: black;
  color: white;
  padding: 50px 125px;
  display: flex;
  gap: 20px;
  /* height: 900px; */
}
.sideNavContainer {
  width: 270px;
  background-color: var(--secondary-color);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.filterRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
  color: var(--gray-color);
  margin: 20px 0;
}
.filterContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  transition: color 0.3s ease;
}
.filterContainer:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.filterContainer > *:last-child {
  animation: arrowDown 0.3s ease forwards;
}
.filterContainer:hover > *:last-child {
  animation: arrowUp 0.3s ease forwards;
}
@keyframes arrowUp {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes arrowDown {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0);
  }
}

/*  TABLE ROWS */
.pairColumn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pairColumn img {
  width: 23px;
  height: 23px;
}

.sideColumn {
  flex: 0.15;
  display: flex;
  align-items: center;
  gap: 7px;
}
.sideColumn > *:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
}
.sideColumn > *:last-child > span:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabHeader > *:last-child {
    width: 100%;
  }
  .titlesContainer > span {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

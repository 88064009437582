.tabContainer {
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.formSection {
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: 45px;
}
.formSection p {
  font-size: 13px;
  margin: 0 !important;
}
.formSection > span {
  font-size: 11px;
  color: #9295a6;
  margin-bottom: 40px;
}

/* FOOTER SECTION */
.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
}
.footerContainer > span {
  color: var(--gray-color);
  font-size: 9.5px;
}
.footerContainer > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inputsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabHeader {
    margin: 0;
  }
}

@media (max-width: 560px) {
  .inputsContainer {
    display: block;
  }
  .tabContainer {
    width: 90vw;
  }
  .footerContainer {
    flex-direction: column-reverse;
    gap: 15px;
    margin-top: 20px;
  }
  .footerContainer > div {
    width: 100%;
  }
  .footerContainer > div > * {
    flex: 1;
  }
  .formSection {
    margin-bottom: 15px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

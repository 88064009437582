.pageContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.formContainer {
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 50px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.row > * {
  flex: 0.45;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 840px) {
  .row {
    flex-direction: column;
  }
  .row > * {
    width: 100%;
    flex: 1;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

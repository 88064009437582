.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 40px 0 20px 0;
}
.header h2 {
  margin: 0 !important;
}
.iconContainer {
  width: 55px;
}

.jobsContainer {
  margin-left: 25px;
}

.mainContainer {

}

.title {
    text-align: center;
    margin-bottom: 47px;
}

.successIconContainer {
    background-color: white;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
}

.spentContainer {
    text-align: center;
}
.spentContainer > div:first-child {
    font-size: 18.5px;
}
.spentContainer > div:last-child {
    font-size: 29px;   
    font-weight:600;
}

.rowsContainer {
    background-color: var(--background-color);
    border-radius: 10px;
    font-size: 12px;
    padding: 14px;
    margin-top: 20px;
}
.rowsContainer > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 7px;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.buttonsContainer > * {
    width: 47% !important;
}
.radioButtonContainer {
    background: none;
    border: 1px var(--border-color) solid;
    border-radius: 5px;
    color: #6C7080;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    height: 54px;
    width: 140px;
    /* flex: .45; */
}

.selectedButtonContainer {
    background-color: #080808;
    border: none;
    color: var(--primary-color);
}
.selectedButtonContainerLight {
    background-color: #EAFCFC;
    border: .5px var(--primary-color) solid;
    color: var(--primary-color);
}

.radioImage {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.iconContainer {
    margin-right: 5px;
}


.radioText {
    
}
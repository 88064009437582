.tabContainer {
  flex: 0.66;
  display: flex;
  flex-direction: column;
}

/* logo image */
.tabContainer img {
  min-width: 152px;
  width: 20%;
  margin-left: auto;
  margin-right: 10%;
  padding: 20px;
}

.formDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20% 20% 20% 32%;
  text-align: center;
}

.title {
  font-size: 33px;
  /* border: 1px blue solid; */
  width: 100%;
  margin-bottom: 25px;
  line-height: 38px;
}

.linksContainer {
  margin: 25px 0;
  font-size: 13px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .formDiv {
    padding: 10vh 0 0 0;
    width: 75vw;
    justify-self: center;
    align-self: center;
  }
  .title {
    text-align: center;
  }
  .linksContainer {
    text-align: center;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

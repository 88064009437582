.durationsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

.durationButton {
  border: none;
  background: none;
  font-weight: 300;
  border-radius: 3.5px;
  color: var(--text-color);
  border: 1px var(--border-color) solid;
}

.durationButton:hover,
.selectedDurationButton {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.card {
    gap: 7px;
    padding: 15px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--secondary-color);
    height: 220px !important;
}

.card img {
    width: 23%;
}

.title {
    font-size: 15px;
    font-weight: 500;
}

.text {
    color: #6C7080;
    font-size: 13.75px;
}


/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 580px) {
    .card {
        flex-direction: row;
        height: fit-content !important;
        align-items: center;
    }
    .card img {
        margin: 16px 16px 16px 12px;
        width: 70px !important;
        height: 70px !important;
    }
    .text {
        margin-bottom: 0;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
.footerContainer {
  position: fixed;
  z-index: 1;
  height: 78px;
  width: 100%;
  background-color: var(--footer-background-color);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}
.footerContainer > * {
  flex: 0.4;
  /* height: 55%; */
  font-size: 15px;
  white-space: nowrap;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (min-width: 991px) {
  .footerContainer {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.formContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 30px;
  max-width: 900px;
  margin: 40px auto;
}

.formSectionTitle {
  margin-bottom: 20px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formSectionTitle h5 {
  font-weight: 400;
}

.stepNumberContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 45px 0;
}
.stepNumberContainer > div:first-child {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: var(--background-color);
}

.stepNumberContainer > div:last-child {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}
.stepNumberContainer > div:last-child > span:first-child {
  font-size: 15p;
}
.stepNumberContainer > div:last-child > span:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 940px) {
  .formContainer {
    margin: 40px 20px;
  }
}
@media (max-width: 1025px) {
  .formRowContainer {
    flex-direction: column;
    gap: 0;
  }
  .formRowContainer > * {
    width: 100%;
  }
  .checkboxInputContainer > * {
    margin: 10px 0;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

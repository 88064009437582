.tabContainer {
  flex: 0.66;
  display: flex;
  flex-direction: column;
}

/* logo image */
.tabContainer img {
  min-width: 152px;
  width: 20%;
  margin-left: auto;
  margin-right: 10%;
  padding: 20px;
}

.formDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  /* padding: 20% 20% 20% 32%; */
  /* height: 87vh; */
  margin: 10vh auto 0 auto;
  width: 100%;
  max-width: 376px;
  transform: translateX(50px);
}

.title {
  font-size: 33px;
  /* border: 1px blue solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  line-height: 38px;
}

.mailInput {
  font-size: 16px;
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  border: none;
}

.mailInput::placeholder {
  color: #9295a6;
}

.mailInput:focus {
  outline: 1px var(--primary-color) solid;
}

.passwordInputContainer {
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  display: flex;
  align-items: center;
}

.passwordInputContainer:focus-within {
  outline: 1px var(--primary-color) solid;
}

/* eye container */
.passwordInputContainer div {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.passwordInputContainer div:hover {
  background-color: #80808029;
  cursor: pointer;
}

.passwordInputContainer input {
  border: none;
  width: 100%;
  font-size: 16px;
}

.passwordInputContainer input:focus {
  outline: none;
}

.passwordInputContainer input::placeholder {
  color: #9295a6;
}

.forgotPassword {
  color: var(--primary-color);
  text-align: right;
  font-size: 12px;
}

.detailsSpan {
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
  margin-bottom: 20px;
}

.registerSpan a {
  color: var(--primary-color);
}

.linksContainer {
  margin: 35px auto;
  font-size: 13px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .formDiv {
    padding: 3vh 0 0 0;
    width: 75vw;
    justify-self: center;
    align-self: center;
    transform: none;
  }
  .title {
    text-align: center;
    margin-bottom: 30px;
  }
  .linksContainer {
    text-align: center;
  }
  .detailsSpan {
    text-align: center;
  }
  .breakLine {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

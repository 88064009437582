.card {
  background-color: var(--secondary-color);
  padding: 25px;
  border-radius: 15px;
  width: fit-content;
  font-weight: normal !important;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 18.3px;
  margin-bottom: 10px;
}

.cardHeader img {
  width: 30px;
}

.cardInfo {
  margin-bottom: 10px;
  font-size: 16px;
}

.priceChange {
  font-size: 15.5px;
  margin-right: 10px;
  display: flex;
  align-items: center;

  flex-wrap: nowrap;
}
.priceChange:first-child {
  font-weight: 500;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 840px) {
  .card {
    display: flex !important;
    height: fit-content !important;
  }

  .card > *:first-child {
    width: 60% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .card > *:last-child {
    width: 40% !important;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.card {
  background-color: var(--secondary-color);
  margin-bottom: calc(var(--gap) * 1.25);
  border-radius: 10px;
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  padding: 15px;
  gap: 15px;
}
.tabsContainer > * {
  width: fit-content !important;
  height: 18px !important;
}

.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
  padding: 120px 20px;
}

.paymentIconsContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}

.headerLeft div {
  color: var(--gray-color);
}

.buyCryptoButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

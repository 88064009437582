.card {
  border: 1px var(--border-color) solid;
  border-radius: 30px;
  padding: 40px 20px 20px 20px;
  position: relative;
  height: 150px;
}

.iconContainer {
  width: 26%;
  margin: 0 auto;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -37px;
  right: 37%;
  left: 37%;
  padding: 0 10px;
  background-color: var(--secondary-color);
}

.title {
  font-size: 15.5px;
}

.description {
  font-size: 13px;
  color: rgb(146, 149, 166);
  margin: auto 0;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1120px) {
  .card {
    padding: 50px 20px 20px 20px;
  }
}

.verifyNumberContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 32px;
  margin: 70px auto;
  width: 430px;
}

.stepNumberContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 45px 0;
}
.stepNumberContainer > div:first-child {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: var(--background-color);
}

.stepNumberContainer > div:last-child {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}
.stepNumberContainer > div:last-child > span:first-child {
  font-size: 15p;
}
.stepNumberContainer > div:last-child > span:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

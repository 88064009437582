.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 28px;
  height: 610px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buySellTabsContainer {
  min-width: 230px;
  margin: 0 auto;
  width: 100%;
}

.buySellTabsContainer button {
  width: 50%;
  padding: 0 0 17px 0;
  background: none;
  border: none;
  color: #6c7080;
  border-bottom: 2px #ccced9 solid;
  font-size: 17px;
}

.buySellTabsContainer .selectedTab {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.tabContentContainer {
  margin-top: 30px;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 7px;
  margin-bottom: 20px;
  margin-top: -20px;
}

.coinPriceSelectPairContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
/* approx price span */
.approxPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}
.approxPrice img {
  width: 25px;
}
.approxPrice > div:first-child {
  display: flex;
  align-items: center;
  gap: 5px;
}
.approxPrice > div:last-child {
  font-size: 13px;
  color: var(--gray-color);
}

.walletAmountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin: 20px 0;
}
.walletAmountIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.buySellInputContainer {
  width: 100%;
  border-radius: 5px;
  border: 1px #ccced926 solid;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  margin-bottom: 25px;
}

.buySellInputContainer:focus-within {
  border-color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellInputContainer:focus-within .buySellInputTitle {
  color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellInputContainer:hover {
  border-color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellLeftSection {
}

.buySellRightSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.buySellRightSection:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.buySellRightSection span {
  font-size: 12px;
  margin: 0 3px;
}

.buySellRightSection img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.buySellInputTitle {
  color: #6c7080;
  font-size: 13px;
}

.buySellInputGroupContainer {
  display: flex;
  align-items: center;
  border-left: 1.5px #3e445b solid;
  padding-left: 5px;
}

.buySellInputGroupContainer input {
  background: none;
  outline: none;
  border: none;
  color: #9295a6;
  font-weight: bold;
}

.buySellInputGroupContainer input:focus {
  outline: none;
}

.buySellInputGroupContainer span {
  color: #9295a6;
  font-weight: bold;
}

.feeInfo {
  color: #9295a6;
  font-size: 12px;
  /* margin-bottom: 50px; */
  font-weight: 400;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ------RESPONSIVENESS------ */
/* -------------------------- */
@media (max-width: 700px) {
  .card {
    padding: 0;
  }
}
/* -------------------------- */
/* -------------------------- */

.pageContainer {
  padding: 35px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: -26px;
}

.pageContainer h1 {
  font-size: 60px;
}
.pageContainer > *:first-child {
  margin-bottom: -30px;
}
/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 700px) {
  .pageContainer {
    text-align: center;
    gap: 30px;
  }
  .pageContainer > *:first-child {
    width: 70px;
  }
  .pageContainer h1 {
    font-size: 40px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

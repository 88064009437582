.card {
  border-radius: 30px;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  width: 280px;
  height: 178px;
  text-align: center;
}

.iconContainer {
}

.title {
  font-size: 15.5px;
}

.description {
  color: var(--gray-color);
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1120px) {
  .card {
    /* padding: 50px 20px 20px 20px; */
  }
}

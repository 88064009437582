.button {
  border-radius: 5px;
  padding: 10px 14px;
  background: none;
  color: var(--gray-color);
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16.5px;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: -8.5px;
}

.button:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.selectedButton {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.dropdownButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease;
  margin-left: 20px;
}

.dropdownButton {
  border-radius: 5px;
  padding: 10px 14px;
  background: none;
  font-size: 14px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16.5px;
  width: 100%;
  color: var(--gray-color);
}
.dropdownButton:hover {
  text-decoration: none;
}
.dropdownButtonSelected {
  color: var(--text-color);
}

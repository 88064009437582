.tabContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 25px;
}
.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.titlesContainer {
  display: flex;
  flex-direction: column;
}
.titlesContainer > *:last-child {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 300;
}

/* Hide header on mobile view */
@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabHeader > *:last-child {
    width: 100%;
  }
  .titlesContainer > span {
    display: none;
  }
}

.container {
  text-align: center;
  background-color: var(--secondary-color);
  padding: 40px var(--horizontal-page-gap);
}

.startTradingLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-decoration: underline;
  color: var(--primary-color);
}
.arrowIconContainer {
  background-color: white;
  height: 20px;
  width: 20px;
  color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(3px) translateY(2px);
  margin-left: 6.5px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-template-rows: repeat(1, auto);
  gap: 30px;
  padding: 35px 0;
  margin-top: 50px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1120px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-template-rows: repeat(2, auto);
    gap: 60px;
  }
  .gridContainer > * {
    /* margin-top: 45px; */
  }
}
@media (max-width: 530px) {
  .gridContainer {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    grid-template-rows: repeat(4, auto);
  }
  .description {
    width: 100%;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.sideNavContainer {
    background-color: var(--secondary-color);
    border-radius: 10px;
}
.pageContainer {
    padding: 65px 10%;
    display: flex;
    justify-content: center;
    gap: 25px;
}
@media (max-width: 1100px) {
    .pageContainer {
        padding: 65px 2%;
    }
}

/* side nav */
.pageContainer > div:first-child { 
    width: fit-content;
    padding: 35px;
    flex: .25;
}
.sideNavHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.sideNavHeader > img {
    width: 40%;
    margin-bottom: 17px;
}
.sideNavHeader > div {
    font-size: 17px;
}
.sideNavHeader > span {
    color: #6C7080;
    font-size: 12px;
}

.sideNavButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.sideNavButtonsContainer > * {
    justify-content: flex-start !important;
    width: 100% !important;
    white-space:nowrap;
}

.pageContainer > div:last-child { 
    width: fit-content;
    flex: .75;
}



/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.mobilePageTitle {
    text-align: left;
    margin: 40px 0 0 53px;
    font-size: 30px;
}

@media (max-width: 840px) {
    .pageContainer {
        gap: 10px;
    }
}

@media (max-width: 820px) {
    .pageContainer {
        padding: 25px 2%;
    }
    .sideNavContainer {
        flex: 1 !important;
    }
    .sideNavContainer img {
        width: 100px;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
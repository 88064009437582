/* ----------PAGE LAYOUT---------- */
.pageContainer {
  padding: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.leftSectionContainer {
  width: 60vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightSectionContainer {
  width: 100%;
  min-width: 312.5px;
  max-width: 365px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/* ----------MARKET COINS---------- */

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 25px;
}

.cardTitle {
  font-weight: 500;
  font-size: 23px;
}

.cardSubTitle {
  color: #9295a6;
  font-size: 14px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.headerSearchContainer {
  color: white;
  border: 1px #ccced926 solid;
  border-radius: 5px;
  padding: 9px 14px;
  display: flex;
  align-items: center;
  width: 300px;
}

.headerSearchContainer input {
  width: 100%;
  background: none;
  border: none;
  color: white;
  padding-left: 13px;
}

.headerSearchContainer input:focus {
  outline: none;
}

.headerSearchContainer input::placeholder {
  color: #9295a6;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.rowContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12.5px;
}

.rowContainer > div img {
  width: 25px;
}

/* .rowContainer > div > span:nth-child(1) {
    flex: .1;
    display: flex;
    align-items: center;
    gap: 3px;
} */
.rowContainer > div > span:nth-child(1) {
  flex: 0.3;
  display: flex;
  align-items: center;
  gap: 7px;
}
.rowContainer > div > span:nth-child(2) {
  flex: 0.15;
}
.rowContainer > div > span:nth-child(3) {
  flex: 0.1;
}
.rowContainer > div > span:nth-child(4) {
  flex: 0.15;
  display: flex;
  gap: 4px;
  justify-content: space-between;
}
.rowContainer > div > span:nth-child(5) {
  flex: 0.15;
}
.rowContainer > div > span:nth-child(6) {
  flex: 0.15;
  display: flex;
  align-items: center;
  gap: 5px;
}

.tableFooter {
  padding: 20px 0 15px 0;
  font-size: 12px;
  color: #6c7080;
}

/* ------------ DATAGRID ------------ */
/* ---------------------------------- */
.coinNameColumn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.coinNameColumn img {
  width: 25px;
  height: 25px;
}
.actionColumn {
  display: flex;
  align-items: center;
  gap: 20px;
}
/* ---------------------------------- */
/* ---------------------------------- */

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */

/* columns to hide for small screens */
/* @media (max-width:  770px) {
    .columnToHide {
      visibility: collapse !important;
    }
} */

/* tab header */
@media (max-width: 900px) {
  .leftSectionContainer {
    width: 50vw;
  }
}
@media (max-width: 900px) {
  .cardHeader {
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    text-align: center;
    margin: 0;
    gap: 20px;
  }
  .cardHeaderRight,
  .cardHeaderRight > * {
    width: 100%;
  }
}

/* MOBILE VIEW */
@media (max-width: 700px) {
  .leftSectionContainer {
    width: 90vw !important;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

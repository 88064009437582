.rowContainer {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 3px 0;
    margin: 4px 0;
    height: fit-content;
}
.rowContainer > div {
    flex: .33;
}
.rowContainer > div:nth-child(2) {
    text-align: center;
}
.rowContainer > div:last-child {
    text-align: right;
}
.card {
  background-color: var(--secondary-color);
  border-radius: 20px;
}

.whiteCircle {
  width: 25px !important;
  height: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px; /*22px original*/
  font-weight: bold;
  border-radius: 50%;
  background-color: white;
  color: var(--primary-color);
}

.pageContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* ------------ HERO ------------ */
.heroContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 8%; /* var(--horizontal-page-gap) */
  padding: 30px 90px;
  gap: 120px;
  overflow: hidden;
}

.heroTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  width: 45%;
}

.heroTextHeaderContainer {
  font-size: 35.75px;
  font-weight: 600;
  line-height: 40px;
}
.heroTextHeaderContainer div:nth-child(3) {
  /* transform: translateY(-20px); */
  color: var(--primary-color);
  white-space: nowrap;
}

.heroTextBodyContainer {
  width: 100%;
}
.heroTextBodyContainer > p {
  font-size: 12px;
}

.heroTextBodyContainer p > a {
  color: var(--primary-color);
  text-decoration: underline;
  margin: 0 4px;
}

.heroTextBodyContainer span {
  font-weight: bold;
  color: var(--primary-color);
  font-size: 13px;
  width: fit-content;
  line-height: 0px;
}

.heroSVG {
  width: 40%;
  max-width: 450px;
  transform: scale(1.8);
}

/* ----------- Top Markets ----------- */
.topMarketContainer {
  margin: 95px var(--horizontal-page-gap);
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-template-rows: repeat(2, auto);
  gap: 30px;
  padding: 35px 0;
}

@media (max-width: 1300px) {
  .gridContainer {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 580px) {
  .gridContainer {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

/* ----------- TRADE AND register ----------- */
.tradeAndRegisterContainer {
  display: flex;
  justify-content: space-between;
  gap: 220px;
  align-items: center;
  background-image: url("../assets/hero2/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 144px calc(var(--horizontal-page-gap) / 3) 144px
    var(--horizontal-page-gap);
}

.tradeAndRegisterButtonsContainer {
  display: flex;
  flex-direction: row;
}
.tradeAndRegisterButtonsContainer > * {
  flex: 1;
}

.tradeAndRegisterContainerLight {
  background-image: url("../assets/hero2/background-light.png");
}

.tradeAndRegisterLeftSection {
  position: relative;
  flex: 0.45;
}
.hero2SVG {
  transform: scale(1.2);
}
.hero2SVGFrame {
  position: absolute;
  top: -45px;
  right: -50px;
  width: 43%;
}

.tradeAndRegisterRightSection {
  flex: 0.55;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.tradeAndRegisterRightSection span {
  color: var(--primary-color);
  font-size: 16.5px;
}
.tradeAndRegisterRightSection h2 {
  width: 100%;
  font-size: 2.1rem;
  width: 100%;
  white-space: nowrap;
}
.tradeAndRegisterRightSection p {
  color: #9295a6;
  font-size: 15px;
}

.tradeAndRegisterRightSection > div {
  display: flex;
  justify-content: space-between;
  gap: 35px;
  align-items: center;
  width: 50%;
  min-width: 285px;
}
.tradeAndRegisterMobileButtonsContainer {
  display: none;
}

@media (max-width: 1220px) {
  .tradeAndRegisterContainer {
    gap: 100px;
  }
}
@media (max-width: 920px) {
  .tradeAndRegisterContainer {
    flex-direction: column-reverse;
    gap: 0;
    padding: 54px var(--horizontal-page-gap) 144px var(--horizontal-page-gap);
  }
  .hero2SVG {
    transform: scale(1);
    margin-bottom: 10px;
  }
  .tradeAndRegisterLeftSection {
    transform: scale(0.85);
    width: 100vw;
  }
  .tradeAndRegisterRightSection {
    align-items: center;
    gap: 11.5px;
  }
  .tradeAndRegisterRightSection * {
    text-align: center;
  }
  .tradeAndRegisterRightSection h2 {
    font-size: 25px;
  }
  .tradeAndRegisterRightSection p {
    width: 100%;
  }
  .tradeAndRegisterRightSection > p br {
    display: none;
  }
  .tradeAndRegisterRightSection > div {
    display: none;
  }
  .tradeAndRegisterMobileButtonsContainer {
    display: flex;
    align-items: center;
    margin-bottom: -209px;
    justify-content: space-between;
    width: 120%;
    gap: 27px;
  }
  .tradeAndRegisterMobileButtonsContainer > * {
    flex: 1;
  }
}

/* ----------- TRADE AND register ----------- */
.newsLetterContainer {
  background-color: var(--secondary-color);
  color: white;
  border-radius: 25px;
  padding: 55px 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--horizontal-page-gap);
  /* width: 82%; */
}

.newsLetterContainer > div:first-child {
  flex: 0.49;
}

.newsLetterContainer > div:last-child {
  flex: 0.49;
  display: flex;
  align-items: center;
  gap: 20px;
}

.newsLetterContainer > div:last-child input {
  flex: 1;
  color: white;
  background: none;
  border: none;
  outline: none;
  border-bottom: 2px white solid;
  padding: 7px 2px;
  transition: border 0.4s ease;
}
.newsLetterContainer > div:last-child input::placeholder {
  color: white;
}
.newsLetterContainer > div:last-child input:focus {
  border-color: var(--primary-color);
  transition: border 0.4s ease;
}

@media (max-width: 840px) {
  .newsLetterContainer {
    flex-direction: column;
    align-items: center;
  }
  .newsLetterContainer * {
    text-align: center;
  }
  .newsLetterContainer > div:first-child {
    flex: 1;
  }

  .newsLetterContainer > div:last-child {
    flex: 1;
    width: 80%;
    justify-content: space-around;
  }
  .newsLetterContainer > div:last-child input {
    text-align: left;
    flex: 0.8;
  }
}

/* ----------- SERVICES CARDS ----------- */
.gridContainerServices {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-template-rows: repeat(2, auto);
  gap: 30px;
  padding: 35px 0;
}

@media (max-width: 840px) {
  .servicesSectionContainer {
    margin-top: 150px !important;
  }
}

@media (max-width: 1200px) {
  .gridContainerServices {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
}

@media (max-width: 580px) {
  .gridContainerServices {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}

.coinChartMobileContainer {
  display: none;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */

@media (max-width: 840px) {
  .heroContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .heroTextContainer {
    align-items: flex-start;
    text-align: left !important;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
  .heroTextContainer p {
    font-size: 17px;
    margin-bottom: 30px;
  }

  .heroSVG {
    width: 100%;
    transform: scale(1.5);
  }

  /* TOP MARKETS */
  .topMarketContainer > div:nth-child(2) {
    font-size: 28px;
    margin-bottom: 25px;
    font-weight: 500;
  }
  .topMarketContainer > div:nth-child(1) {
    font-size: 17px;
  }

  /* HIDING DESKTOP ELEMENTS */
  .coinChartGridContainer {
    display: none;
  }

  /* SHOWING MOBILE EELEMENTS */
  .coinChartMobileContainer {
    display: block;
  }
  .coinChartMobileContainer > * > *:first-child > * > * {
    transform: scale(0.97);
  }

  /* REACT SLICK MOBILE */
  .topMarketContainer {
    margin: 80px 6%;
  }
}

@media (max-width: 730px) {
  .heroContainer {
    margin: 0 6vw;
    padding: 30px;
  }
  .heroTextBodyContainer {
    width: unset;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

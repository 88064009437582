.selectedCoinContainer {
}

.selectedCoinContainer > span {
  color: #6c7080;
  font-size: 11px;
}

.selectedCoinContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 12px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  border: 1.45px #ccced926 solid;
  margin-bottom: 30px;
  height: 45px;
}
.selectedCoinContainer > div:hover {
  cursor: pointer;
}

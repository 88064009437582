.label {
  color: var(--gray-color);
  font-size: 11px;
  text-align: left;
}

.inputContainer {
  height: 43px;
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
  min-width: 140px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowsContainer {
    height: 50vh;
    margin-top: 20px;
    overflow-y: scroll;
    padding-right: 10px;
}

.rowsContainer > *:first-child {
    margin-top: 0;
}

.row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    padding: 5px 0 5px 5px;
    border-radius: 3px;
}
.row:hover {
    cursor: pointer;
    background-color: black;
    color: var(--primary-color);
}
.row > img {
    width: 30px;
    height: 30px;
}

.notFound {
    color: var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 30px 0;
}

.tabsContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    border-bottom: 1px var(--gray-color) solid;
    margin-bottom: 20px;
}

.marketRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    padding: 5px 0 5px 5px;
    border-radius: 3px;
}
.marketRow:hover {
    cursor: pointer;
    background-color: black;
    color: var(--primary-color);
}

.marketRow img {
    width: 30px;
}

/* LIGHT MODE */
.lightRow:hover {
    background-color: #a8cfca1c;
}
.MuiList-root {
  background-color: black;
  color: white;
  max-height: 195px;
  overflow-y: auto;
}
.MuiPopperUnstyled-root {
  z-index: 1;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 255, 251, 0.282) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.country_input_container .MuiSelect-popper {
  height: 230px !important;
}
.country_input_container .MuiPopperUnstyled-root {
  height: 230px !important;
}

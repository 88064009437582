.formDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 7% 11.5% 20% 27.5%;
}

.title {
  font-size: 33px;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
  line-height: 38px;
}

.subtitle {
  font-size: 11px;
  text-align: left;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .formDiv {
    padding: 10vh 10% 0 10%;
    /* justify-self: center; */
    /* align-self: center; */
  }
  .subtitle {
    width: 50vw;
    margin: 0 auto;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

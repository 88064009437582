.actionButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.subscriptionDurationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.subscriptionDurationSpan {
  font-weight: 500;
}
.aprSpan {
  color: var(--success-color) !important;
}
.daysLabel {
  border: 0.55px var(--text-color) solid;
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 9px;
}

.button {
  position: relative;
  border-radius: 5px;
  color: #00191e;
  background-color: var(--primary-color);
  /* height: 20px !important; */
  min-height: 37px;
  width: fit-content;
  outline: none;
  border: none;
  padding: 0 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 400;
}

.button:hover {
  background-color: #c8ffff;
  color: #00191e;
  transition: all 0.3s ease;
}

.button a {
  color: inherit;
}

.dark {
  background-color: black;
  color: var(--primary-color);
}
.dark:hover {
  color: #c8ffff;
  background-color: #c8f4ff30 !important;
}

.outlined {
  color: var(--gray-color);
  border: 1.45px var(--gray-color) solid;
  background: transparent;
}
.outlined:hover {
  background-color: rgba(0, 0, 0, 0.419);
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.outlined .bubble {
  display: none;
}

.link {
  width: fit-content !important;
  background: none !important;
  color: var(--primary-color);
  padding: 0; /* 0 27px */
  height: 0;
  min-height: 12px !important;
  border-radius: 0;
}
.link:hover {
  text-decoration: underline;
  cursor: pointer;
  background: none !important;
  color: var(--primary-color);
}
.link .bubble {
  display: none !important;
}

.icon {
  transform: translateX(-6px) translateY(-1px);
}

/* .bubble {
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #c8ffff;
    bottom: -5px;
    border-radius: 50%;
    transform: scale(1);
} */

.bubble {
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 0;
  border-radius: 160px;
  background-color: #c8ffff;
  opacity: 1;
  /* visibility: hidden; */
  /* transition: visibility 0s, opacity 0.5s linear; */
}

.button:hover .bubble {
  visibility: visible;
  opacity: 1;
  animation: ripple 0.8s ease forwards;
}

.button:not(:hover) .bubble {
  animation: rippleOff 0.4s ease forwards;
}

@keyframes ripple {
  from {
    width: 0;
    height: 0;
    bottom: -5px;
  }
  to {
    width: 200%;
    height: 200%;
    bottom: -10px;
    opacity: 0;
  }
}

@keyframes rippleOff {
  from {
    width: 200%;
    height: 200%;
    bottom: -10px;
    opacity: 0;
  }
  to {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
}

/* loader animation */
.loading {
  filter: saturate();
}
.loading:hover {
  cursor: default !important;
}
.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.17);
}
.loader {
  position: absolute !important;
  right: 6.5px;
}

/* TAB TYPE STYLING */
.tab {
  position: relative;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--gray-color);
  padding: 0px;
  margin: 10px;
  font-size: 13px;
  background: none;
  border: none;
  margin: 0;
  padding-bottom: 20px;
}
.tab:hover {
  /* color: var(--primary-color)  ; */
  text-decoration: none;
  cursor: pointer;
}
.selected {
  color: var(--primary-color) !important;
  border-bottom: 2px var(--primary-color) solid;
  /* text-shadow: 0 0 10px var(--primary-color); */
}
.bottomLine {
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: var(--primary-color);
  bottom: -1px;
  left: 0;
  animation: bottom-line-hover 2s linear ease;
}

.tab:hover .bottomLine {
  animation: showBottomLineHover 0.25s ease-in-out forwards;
}

.tab:not(:hover) .bottomLine {
  animation: hideBottomLineHover 0.25s ease-in-out forwards;
  opacity: 0;
  transition: opacity 0.6s;
}

.selected:hover .bottomLine {
  animation: none !important;
}

.selected:not(:hover) .bottomLine {
  animation: none !important;
}

@keyframes showBottomLineHover {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes hideBottomLineHover {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

/* LOGGED OUT BUTTON */
.loggedOutContainer {
  background-color: var(--background-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  height: 45px;
}
.loggedOutContainer a {
  color: var(--primary-color);
}

/* DISABLED STATE */
.disabled {
  background-color: #96969a;
  color: #545766;
}

/* LIGHT MODE VARIANTS */
.light.dark {
  background-color: #e9eaf0;
}
.light.dark:hover {
  color: var(--text-color);
}

.light.loggedOutContainer {
  /* background-color: white; */
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 480px) {
  .tab * {
    animation: none;
  }
  .bottomLine {
    animation: none;
  }

  .tab:hover .bottomLine {
    animation: none;
  }

  .tab:not(:hover) .bottomLine {
    animation: none;
  }

  .selected:hover .bottomLine {
    animation: none !important;
  }

  .selected:not(:hover) .bottomLine {
    animation: none !important;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

/* .pageContainer {
    --gap: 5px;
    padding: 0 var(--gap) !important;
    margin: 0 !important;
    margin-top: var(--gap) !important;
} */

/* .pageContainer > div {
    padding: var(--gap) !important;
} */

/* first container in each column */
/* .pageContainer > div > div:first-child{
    margin-bottom: calc(var(--gap) * 2);
} */

/* -------------- LAYOUT -------------- */
.pageContainer {
  --gap: 20px;
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  margin: var(--gap);
}
.leftContainer {
  height: 1175.5px;
  flex: 0.21;
}
.middleContainer {
  flex: 0.55;
  width: 20vw;
  height: fit-content;
}
.rightContainer {
  flex: 0.24;
  height: fit-content;
}
.ordersContainer {
  margin: 20px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.tableOrderBookTradesContainer {
  display: flex;
  justify-content: space-between;
  gap: var(--gap);
  height: 327px;
  margin: var(--gap) 0;
}
.tableOrderBookTradesContainer > * {
  flex: 1;
}

@media (max-width: 990px) {
  .pageContainer {
    --gap: 10px;
  }
  .leftContainer {
    display: none;
  }
  .pageContainer {
    justify-content: center;
  }
  .middleContainer {
    /* flex: 0.62; */
    flex: 1;
  }
  .rightContainer {
    flex: 0.38;
  }
  .ordersContainer {
    margin: 17px 10px 10px 10px;
  }
}

@media (min-width: 840px) {
  .footerContainer {
    display: none;
  }
}
@media (max-width: 840px) {
  .middleContainer {
    flex: 1;
  }
}

/* ---------------------------------------- */
/* ---------------------------------------- */

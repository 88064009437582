.estimateContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.estimateContainer * {
  font-size: 12px;
}
.estimateContainer > *:first-child {
  color: var(--gray-color);
}
.estimateContainer > *:last-child {
  color: var(--primary-color);
}

.datesContainer {
  height: 60px;
  display: flex;
}

.dateDotsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 100%;
  padding: 5px;
}
.fromDot {
  width: 8.5px;
  height: 8.5px;
  border-radius: 50%;
  background-color: var(--text-color);
  position: relative;
}
.fromDot > * {
  background-color: var(--primary-color);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 0.25px), calc(-50% + 0.25px));
}
.toDot {
  width: 8.5px;
  height: 8.5px;
  border-radius: 50%;
  background-color: var(--text-color);
}

.dateProgressBar {
  background-color: var(--gray-color);
  height: 66%;
  width: 2px;
  transform: translateX(-0.25px);
}
.progress {
  background-color: var(--primary-color);
  width: 100%;
  height: 40%;
}

.dateRowsContainer {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.dateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dateRow > *:first-child {
  color: var(--gray-color);
}

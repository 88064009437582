.description {
  font-size: 11px;
  margin: 30px 0;
}

.networksContainer {
  border: 1px var(--border-color) solid;
  padding: 11px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
}
.networksContainer > button {
  background: none;
  border: none;
  text-align: left;
  padding: 11.5px 10px;
  border-radius: 5px;
}
.networksContainer > button:hover {
  background-color: var(--background-color-inverted);
}

.boxLabel {
  font-size: 11px;
  color: var(--gray-color);
}
/* ---OLD DESIGN--- */
.menuItemContainer * {
  margin: 0;
}
.menuItemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
}

.menuItemContainer > p {
  color: var(--text-color);
  font-size: 14px;
  margin: 0;
}

.menuItemContainer > div {
  color: var(--gray-color);
  font-size: 11.5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
/* --------------- */

// fonts imports
@font-face {
  font-family: 'Outfit';
  src: url('../../fonts/Outfit/Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

body {
  // font-family: 'Overpass', sans-serif;
  font-family: 'Outfit', 'Overpass', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  // color: #18214D;
  background: #FFFFFF;
  font-weight: 400;
}
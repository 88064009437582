/* Moved to orderbookBody */
.card {
  height: 100%;
}
/* .cardTitle {
  font-size: 13px;
  font-weight: bold;
} */

.sortingContainer {
  color: #6c7080;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.sortingContainer > span {
  /* border: 2px red solid; */
  flex: 0.33;
  width: fit-content;
  padding: 10px 0;
}
.sortingContainer > span:nth-child(2) {
  text-align: center;
}
.sortingContainer > span:last-child {
  text-align: right;
}

.lastTrade {
  padding: 8px 0;
  margin: 12px 0px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px var(--border-color) solid;
  border-bottom: 1px var(--border-color) solid;
}
.lastTrade img {
  margin-left: 10px;
}

.bidsContainer {
  height: 267px;
  overflow: auto;
}
.asksContainer {
  height: 267px;
  overflow: auto;
}
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

/* ------------ RESPONSIVENESS ------------ */

@media (max-width: 990px) {
  .bidsContainer {
    height: 193px;
  }
  .asksContainer {
    height: 193px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

/* QR modal */
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px var(--border-color) solid;
  padding-bottom: 10px;
}

.destinationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.destinationContainer > span {
  color: #6c7080;
}
.destinationContainer > div {
  border: 1px #6c7080a8 solid;
  width: 100%;
  padding: 0 0 0 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.destinationContainer > div > span {
  /* flex: 1; */
  margin-right: auto;
  font-size: 10px;
}

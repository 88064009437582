.headerContainer {
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  border-bottom: 0.3px #aeb1bf80 solid;
  padding: 0 7%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.headerLogo {
  width: 120px;
}
.headerLogo:hover {
  cursor: pointer;
}

.userPicture {
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
}

.divider {
  height: 50px;
  width: 0.6px;
  background-color: #aeb1bf80;
  margin: 0 20px;
  border-radius: 20px;
  align-self: center;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftContainer {
  display: flex;
  height: 100%;
  padding-left: 20px;
}

.rightContainerSignedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.headerButtons {
  height: 50px;
  border: 0;
  background-color: #080808;
  color: #99a0bb;
  padding: 14px 14px 12px 14px;
  border-radius: 5px;
  margin: 0 10px;
  display: flex;
  font-weight: 300;
  font-size: 12px;
  align-items: center;
  white-space: nowrap;
}
.headerButtonLight {
  background-color: #f0f1f5;
}
.headerButtonLight + .selectedHeaderButton {
  background: #eafcfc;
}

.selectedHeaderButton {
  outline: 2px var(--primary-color) solid !important;
  color: var(--primary-color);
}

.usernameStatusContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.usernameStatusContainer > *:last-child {
  margin-right: 16px;
}

.userName {
  color: #6c7080;
  transition: color 0.2s ease;
  white-space: nowrap;
}

.userName:hover {
  cursor: pointer;
  color: var(--primary-color);
  transition: color 0.2s ease;
}

.userName:hover > *:last-child {
  animation: arrowDown 0.3s ease forwards;
}
.userName:hover > *:last-child {
  animation: arrowUp 0.3s ease forwards;
}
@keyframes arrowUp {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes arrowDown {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0deg);
  }
}

.rightContainerLoggedOut {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  gap: 20px;
}

/* WALLET POPPER */
.walletPopperContainer {
  width: fit-content;
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: 0 0 7.5px 7.5px;
  transform: translateY(22px);
}
.walletPopperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.walletPopperHeader > div {
  display: flex;
  align-items: center;
  gap: 13px;
  color: var(--gray-color);
}

.walletBalanceContainer {
  margin: 15px 0;
}
.walletBalanceContainer > span:first-child {
  font-size: 21.5px;
}
.walletBalanceContainer > span:last-child {
  color: var(--gray-color);
  font-size: 12px;
}

.depositWithdrawContainer {
  padding: 10px;
  background-color: var(--background-color);
  border-radius: 3.75px;
}
/* rows */
.depositWithdrawContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin: 4px 0;
}
.depositWithdrawContainer > div > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.depositWithdrawContainer > div > div:first-child {
  color: var(--gray-color);
}
.depositWithdrawContainer > div > div:last-child {
  font-size: 13px;
}

.walletPopporButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 25px;
  margin-top: 30px;
}
.walletPopporButtonsContainer > * {
  height: 10px;
}

.pendingIcon {
  background-color: white;
  color: var(--warning-color);
  border-radius: 50%;
  height: 17.5px;
  width: 17.5px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}
.pendingIcon > * {
  transform: translateY(1.25px);
}

/* --------------- MEDIA QUERIES --------------- */
@media screen and (max-width: 1270px) {
  .headerContainer {
    padding: 0;
  }
}

/* --------------- RESPONSIVENESS --------------- */
/* ---------------------------------------------- */
.mobileThemeToggleContainer {
  align-items: center;
  display: none !important;
  height: 40px;
  width: 40px;
}
.mobileThemeToggleContainer:hover {
  color: var(--primary-color);
  transition: all 0.275s;
}

@media (min-width: 1085px) {
  .menuButtonContainer {
    display: none;
  }
}
@media (max-width: 435px) {
  .headerContainer {
    border-radius: 0 0 15px 15px;
    border-color: #aeb1bf3d;
  }
}
@media (max-width: 1085px) {
  .linksContainer,
  .rightContainerSignedIn,
  .rightContainerLoggedOut {
    display: none;
  }
  .mobileThemeToggleContainer {
    display: flex !important;
  }
  .leftContainer {
    align-items: center;
  }
  .leftContainer > .divider {
    height: 34px;
  }
}
/* ---------------------------------------------- */
/* ---------------------------------------------- */

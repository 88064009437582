.pageContainer {
  max-width: 1100px;
  padding: 0 20px;
  margin: 0 auto;
}

.videoOuterContainer {
  border-radius: 20px;
  padding: 35px;
  background-color: var(--secondary-color);
  margin-bottom: 90px;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
  height: 0;
  overflow: hidden;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 799px) {
  .videoOuterContainer {
    padding: 15px;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.summaryNumbersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.summaryNumbersContainer > *:first-child {
  color: var(--gray-color);
}
.summaryNumbersContainer > *:last-child {
  color: var(--primary-color);
}

.summaryText {
  color: var(--gray-color);
  font-size: 10px;
  margin: 10px 0 20px 0;
}

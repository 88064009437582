.container {
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1.2fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.button {
  border: none;
  background: none;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  font-weight: 300;
}
.button:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
}
.selectedTimeframe {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.timeframeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  height: 34px;
  border-radius: 5px;
  gap: 5px;
  font-weight: 300;
}
.timeframeButton:hover {
  color: var(--primary-color);
}
@media (max-width: 379px) {
  .timeframeButton {
    font-size: 11.75px;
    height: 20px !important;
    padding: 0 5px;
  }
}
.selectedTimeframeDropdownButton {
  background-color: var(--primary-color-opacity);
  border-radius: 2px;
  height: 24px;
}

/* BUY/SELL INPUT CONTAINER */
.buySellInputContainer {
  width: 100%;
  border-radius: 5px;
  border: 1px #ccced926 solid;
  padding: 9px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  transition: all 0.2s ease;
}
.buySellInputContainerLight {
  border: 1px #ccced9 solid;
}
.buySellInputContainer:focus-within {
  border-color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellInputContainer:focus-within .buySellInputTitle {
  color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellInputContainer:hover {
  border-color: var(--primary-color);
  transition: all 0.2s ease;
}

.buySellLeftSection {
  width: 75.5%;
}

.buySellRightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.buySellRightSection:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.notHoverable {
  color: var(--text-color) !important;
  cursor: default;
}

.buySellRightSection > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.secondaryTitle {
  color: #6c7080;
  font-size: 11px;
  transform: translateY(-5px) translateX(-4px);
  white-space: nowrap;
}

.buySellRightSection span {
  font-size: 12px;
  margin: 0 3px;
}

.buySellRightSection img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.buySellInputTitle {
  color: #6c7080;
  font-size: 13px;
  transform: translateY(-5.5px);
}

.buySellInputGroupContainer {
  display: flex;
  align-items: center;
  border-left: 1.5px #3e445b solid;
  padding-left: 5px;
}

.buySellInputGroupContainer input {
  background: none;
  outline: none;
  border: none;
  color: #9295a6;
  font-weight: 400;
  /* width: 40px; */
}
.buySellInputGroupContainer input:focus {
  outline: none;
}
.buySellInputGroupContainer input::placeholder {
  color: #9295a6;
}

.buySellInputGroupContainer span {
  color: #9295a6;
  font-weight: bold;
}

.feeInfo {
  color: #9295a6;
  font-size: 12px;
  margin-bottom: 50px;
}

.errorContainer {
  border-color: var(--danger-color) !important;
}
.errorText {
  font-size: 11px;
  color: var(--danger-color);
}

.footerSectionContainer {
    margin: 0 20px 60px 20px;
}

.footerSectionContainer h1 {
    font-size: 15.2px;
    margin-bottom: 33px;
    text-align: left;
    /* transform: translateX(-7px); */
}

.linksContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 13.5px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 500px) {
    .footerSectionContainer h1 {
        font-size: 20px;
    }
    .footerSectionContainer > div > * {
        font-size: 16px !important;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
.pageContainer {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
}
.pageContainer img {
  flex: 0.43;
}

.headerContainer {
  text-align: left;
  margin-top: 105px;
  margin-bottom: 120px;
  flex: 0.55;
}

.headerContainer > span {
  color: var(--primary-color);
  font-size: 30px;
  width: 400px;
}
.headerContainer > h1 {
  font-size: 80px;
}
.headerContainer > p {
  color: var(--gray-color);
  font-size: 16px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1350px) {
  .pageContainer {
    width: 80vw;
  }
  .pageContainer img {
    flex: 0.35;
    width: 350px;
  }
}
@media (max-width: 1100px) {
  .pageContainer img {
    flex: 0.25;
    width: 275px;
  }
}
@media (max-width: 1000px) {
  .pageContainer {
    flex-direction: column;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.container {
    width: 87%;
    margin: 4px auto;
    display: flex;
    align-items: center;
    color: var(--gray-color);
    /* height: 40px; */
    border-radius: 4.5px;
    padding: 13px 17px;
    font-size: 15px;
    gap: 10px;
    transition: all .3s ease;
}
.container:hover {
    cursor: pointer;
    color: var(--primary-color);
    transition: all .3s ease;
}

.isSelected {
    color: var(--primary-color);
}

.arrowIcon {
    margin-left: auto;
}
.rc-slider .rc-slider-mark-text {
  color: var(--gray-color);
  font-size: 12px;
}

/* Active mark text styles */
.rc-slider .rc-slider-mark-text-active {
  color: var(--primary-color);
  font-weight: bold;
}

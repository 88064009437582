.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 160px 0 0 0;
}
.headerContainer > p {
  color: var(--gray-color);
  text-align: center;
  font-size: 16px;
  margin: 60px 0 100px 0;
}

.card {
  border-radius: 20px;
  padding: 105px;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  height: 370px;
  gap: 50px;
}
.card p {
  color: var(--gray-color);
  font-size: 16px;
}
.card img {
  width: 320px;
}

.pageContainer .card:last-child {
  flex-direction: row-reverse;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
/* tab header */
@media (max-width: 1120px) {
  .headerContainer p {
    width: 70vw;
  }
  .card {
    /* padding: 50px; */
    width: 90vw;
    margin: 0 auto 80px auto;
  }
  .card p {
    font-size: 17px;
  }
  .card img {
    width: 200px;
  }
}

@media (max-width: 766px) {
  .card {
    height: fit-content;
    flex-direction: column;
    padding: 50px;
  }
  .pageContainer .card:last-child {
    flex-direction: column;
  }
  .wenbitLogo {
    width: 340px !important;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.container {
  text-align: center;
  margin: 40px auto;
  max-width: 1000px;
}
.sectionTitle {
  font-size: 18px;
  color: var(--primary-color);
}
.title {
  font-size: 42px;
}
.description {
  color: var(--gray-color);
}

@media (max-width: 840px) {
  .container {
    padding: 0 20px;
  }
  .title {
    font-size: 27.5px;
  }
}

.card {
  border-radius: 10px;
  background-color: var(--secondary-color);
  padding: 18px 25px 25px 25px;
  color: white;

  /* ------------------------------- */
  margin-top: -20px; /* TEMP FIX FOR NEW ORDERS LAYOUT */
  /* ------------------------------- */
}

.linksContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.linksContainer a {
  margin: 0;
  padding: 0 0 25px 0;
  color: #6c7080;
}

/* .tableHeader > span:first-child {
    text-align: left !important;
} */
/* .tableHeader > span:last-child {
    text-align: right;
} */

/* .orderRowsContainer {
    height: 125px;
    overflow-y: scroll;
}
.orderRowsContainer > div {
    display: flex;
    margin: 6px 0;
} */

.orderRowsContainer > div > span {
  /* flex: 1; */
  font-size: 12px;
}
.orderRowsContainer > div > span:nth-child(1) {
  flex: 0.2;
  color: #6c7080;
}
.orderRowsContainer > div > span:nth-child(2) {
  flex: 0.15;
}
.orderRowsContainer > div > span:nth-child(3) {
  flex: 0.2;
}
.orderRowsContainer > div > span:nth-child(4) {
  flex: 0.2;
}
.orderRowsContainer > div > span:nth-child(5) {
  flex: 0.15;
}
.orderRowsContainer > div > span:nth-child(6) {
  flex: 0.1;
  color: #6c7080;
}

.orderRowsContainer > div > span:nth-child(2) {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.orderRowsContainer > div > span:nth-child(2) > *:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
}
.orderRowsContainer > div > span:nth-child(2) > *:last-child > *:last-child {
  color: #6c7080;
  font-size: 9px;
}

/* LOGGED OUT */
.loggedOutContainer {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}

.loggedOutContainer a {
  color: var(--primary-color);
  margin: 0 4px;
}

/* MUI DATA COLUMNS */
.dateColumn {
  color: var(--gray-color);
}

.sideColumn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.sideColumn > *:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
}
.sideColumn > *:last-child > *:last-child {
  color: #6c7080;
  font-size: 9px;
}

.pairColumn {
}

.priceColumn {
}

.amountColumn {
}

.cancelColumn {
}

.stateColumn {
  color: var(--gray-color);
  font-size: 12.35px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 840px) {
  /* .tableButton {
        display: none;
    } */
}
.mobileColumn {
  border-bottom: 1px var(--gray-color) solid;
  width: 97%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.mobileColumn > span:not(:first-child) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
}
.mobileColumn > span:first-child {
  justify-content: flex-start;
  margin-bottom: 10px;
}
.mobileColumn > span > span:first-child {
  font-weight: 500;
}
.mobileColumn > span:last-child {
  margin-bottom: 10px;
  margin-top: -5px;
}

.marketNameRowMobile {
  font-weight: 500;
  font-size: 16.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.marketNameRowMobile > img {
  width: 25px;
}
/* ---------------------------------------- */
/* ---------------------------------------- */

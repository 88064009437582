.container {
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px var(--border-color) solid;
  padding: 40px 0;
}

.leftContainer p {
  color: var(--gray-color);
  font-size: 15px;
  margin: 10px 0;
}
.leftContainer span {
  color: var(--gray-color);
  font-size: 13px;
}
.rightContainer {
}

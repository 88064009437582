.mainContainer {
  border-bottom: 1px var(--border-color) solid;
  height: 100%;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 17px;
  /* padding: 10px 0; */
}

.rowsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 6px 0 0 0;
  margin-bottom: 20px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.titleSpan {
  font-weight: 300;
  /* color: var(--gray-color); */
}
.valueSpan {
  color: var(--text-color);
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

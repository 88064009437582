.pageContainer {
    padding: 70px 6.5%;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
}

.imageContainer {
    flex: .57;
    position: relative;
}
.imageContainer > img {
    width: 100%;
    height: 570px;
    object-fit: cover;
    border-radius: 15px;
}
.status {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: var(--primary-color);
    color: black;
    padding: 10px 28px;
    border-radius: 8.5px;
    font-size: 22.5px;
}

.coinDetailsHeadingContainer {
    flex: .38;
}

.coinTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coinDetailRowsContainer {
    color: var(--gray-color);
    padding: 10px;
    background-color: var(--secondary-color);
    border: 1.58657px solid rgba(204, 206, 217, 0.15);
    border-radius: 7.93287px;
    font-size: 16px;
    margin: 20px 0;
}
.coinDetailRowsContainer > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    color: #AEB1BF;
}

.progressContainer {
    font-size: 25px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 50px;
}

.headingTimingSpan {
    font-size: 18px;
    margin: 10px 0;
}

.headingMainTimer {
    font-weight: 600;
    font-size: 40px;
    color: var(--primary-color);
}


/* TOKEN DESCRIPTION */
.tokenDescriptionContainer {
    font-size: 20px;
}
.tokenDescriptionContainer > h2 {
    font-size: 45px;
    margin-bottom: 10px;
}


/* PROJECT INTRODUCTION / FOUNDER CARD CONTAINER  */
.introductionFounderContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 80px;
    margin-top: 110px;
}

.projectIntroductionContainer {
    flex: .5;
}
.projectIntroductionContainer > h2 {
    font-size: 45px;
    margin-bottom: 20px
}
.projectIntroductionContainer > p {
    font-size: 18px;
}

.founderCard {
    flex: .5;
    border-radius: 15px;
    background-color: var(--secondary-color);
    padding: 40px;
}
.founderInfoContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
}
.founderInfoContainer span {
    font-weight: 600;
    font-size: 30px;
}
.founderInfoContainer img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}
.founderCard p {
    font-size: 18px;

}


/* SOCIALS */
.socialsSection {
    margin: 30px 0 190px 0;
}

.socialsSection h2 {
    margin-bottom: 20px;
}

.socialsContainer {
    display: flex;
    align-items: center;
    gap: 70px;
}

.socialsContainer > div {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 19px;
}
.socialsContainer > div> *:first-child {
    color: var(--gray-color);
}


/* TOKEN SALE AND ECONOMICS */
.tokenSaleSection {

}
.tokenSaleSection h2 {
    font-size: 40px;
    margin-bottom: 60px;
}

.salesCard {
    padding: 20px;
    border-radius: 14.45px;
    width: 870px;
    background-color: var(--secondary-color);
}
.salesCard > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 10px;
    font-size: 18px;
    border-top: .3px var(--gray-color) solid;
}
.salesCard > div:first-child, .salesCard > div:last-child {
    border-top: none;
}
.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.pageContainer {
  padding: 65px 10%;
  display: flex;
  justify-content: center;
  gap: 20px;
  /* height: 900px; */
}
@media (max-width: 1500px) {
  .pageContainer {
    padding: 65px 2%;
  }
}
.sideNavContainer {
  background-color: var(--secondary-color);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: fit-content;
  flex: 0.25 1;
}
.sideNavContainer * {
  white-space: nowrap;
}
.mainContainer {
  flex: 1;
  height: 100%;
  width: 10vw;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.mobilePageTitle {
  text-align: left;
  margin: 40px 0 0 53px;
  font-size: 30px;
}

@media (max-width: 820px) {
  .pageContainer {
    padding: 25px 2%;
    gap: 10px;
  }
  .sideNavContainer {
    flex: 1 !important;
  }
  .sideNavContainer img {
    width: 100px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.tabContainer {
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  height: 100%;
}

.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 10px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
  .tabContainer {
    width: 90vw;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

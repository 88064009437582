.navHeaderContainer {
  width: 100%;
  /* height: 95px; */
  height: fit-content;
  display: flex;
  align-items: center;
}

.closeButtonContainer {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.divider {
  width: 80%;
  margin: 10px auto;
  background-color: var(--border-color);
  height: 1px;
}

.userDataContainer {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: 17px;
  width: 320px; /* 290px */
  padding: 0 17px 0 17px;
}

.userDataHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.userDataHeader > div {
  width: 74%;
}
.userDataHeader > div > div:first-child {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.userDataHeader > div > div:first-child > *:last-child {
  /* kyc tag in header */
  margin-top: -80px;
}
.userDataHeader > div > div:last-child {
  color: var(--gray-color);
  font-size: 14px;
}
.userDataHeader > img {
  width: 50px;
  border-radius: 50%;
}

.walletBalanceTitleContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  color: var(--gray-color);
  font-size: 14px;
  margin: 11px 0 -15px 0;
}

.signInButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  padding-bottom: 20px;
  width: 290px;
}
.signInButtonsContainer > * {
  width: 70%;
}

/* DROPDOWN */
.dropdownContainer {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 5px;
  gap: 10px;
  width: 75%;
  margin: 0 auto;
  display: flex;
  padding: 0 10px;
}
.dropdownContainer > * {
  color: var(--gray-color);
}
.dropdownContainer > *:first-child {
  margin-top: 10px;
}
.dropdownContainer > *:last-child {
  margin-bottom: 10px;
}
.walletDropdownOpen {
  height: 272px;
}
.dropdownContainer > * {
  width: 100% !important;
  justify-content: flex-start !important;
}
.walletDrawerButtonOpen {
  color: var(--primary-color) !important;
}
.walletDrawerButtonOpen > *:last-child {
  transform: rotate(90deg);
}

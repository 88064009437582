.pageContainer {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.pageContainer h2 {
    color: white;
    opacity: .9;
    font-size: 230px;
    font-weight: bold;
}

.pageContainer p {
    color: white;
    opacity: .9;
    font-size: 45px;
    font-weight: 500;
    margin-top: -20px;
}

.pageContainer img {
    position: absolute;
    top: 0;
    bottom: 0;
    object-fit: contain;
    height: 100%;
    padding: 30px 0;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 720px) {
    .pageContainer h2 {
        font-size: 140px;
    }

    .pageContainer p {
        font-size: 35px;
    }
} 
/* ---------------------------------------- */
/* ---------------------------------------- */
.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.findJobsButton {
  width: fit-content;
  margin: 0 auto 90px auto;
}

.statsContainer {
  border-radius: 20px;
  padding: 60px 120px;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
}
.statsContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statsContainer > div > span:first-child {
  font-weight: 500;
  font-size: 55px;
}
.statsContainer > div > span:last-child {
  font-weight: 300;
  font-size: 22px;
  color: var(--primary-color);
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 920px) {
  .statsContainer {
    padding: 60px;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.cardContainer {
  max-width: 350px;
  margin: 0 auto;
}

.projectImgContainer {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.status {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--text-color-inverted);
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 5px 20px;
}

.cardContainer > h2 {
  color: var(--primary-color);
  margin-top: 10px;
}

.detailsContainer {
  background-color: var(--secondary-color);
  border: 1.15574px solid rgba(204, 206, 217, 0.15);
  border-radius: 5.77869px;
  color: var(--gray-color);
  padding: 12px 8px;
  margin: 10px 0;
}
.detailsContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* CARD FOOTER */
.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.footerContainer > *:last-child {
  flex: 0.5;
}

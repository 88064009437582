.selectNetwordContainer {
  position: relative;
}

.selectNetwordContainer > span {
  color: #6c7080;
  font-size: 11px;
}

.selectNetwordContainer > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 7px 12px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  border: 1.45px #ccced926 solid;
  margin-bottom: 30px;
  height: 45px;
}
.selectNetwordContainer > div:hover {
  cursor: pointer;
}

.selectedNetwork {
  font-size: 16px;
}

.errorInput {
  outline: 1px var(--negative-color) solid !important;
}
.errorSpan {
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  color: var(--negative-color) !important;
}

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 17px 17px 5px 17px;
  height: 500px;
}
.flexContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.cardTitle {
  font-size: 13px;
  font-weight: bold;
}

.sortingContainer {
  color: #6c7080;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.sortingContainer > span {
  /* border: 2px red solid; */
  flex: 0.33;
  width: fit-content;
  padding: 10px 0;
}
.sortingContainer > span:nth-child(2) {
  text-align: center;
}
.sortingContainer > span:last-child {
  text-align: right;
}

.rowsContainer {
  overflow: auto;
  height: 410px;
}
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 990px) {
  .card {
    height: 100%;
    overflow: hidden;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.header {
  color: var(--gray-color);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 6px;
}

.dropdownContainer {
  border: 1px var(--border-color) solid;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  max-height: 300px;
}
.emptyStateButton {
  color: var(--gray-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  background: none;
  transition: all 0.3s ease;
}

.item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  /* font-weight: 400; */
  width: 100%;
  border: none;
  background: none;
  transition: all 0.3s ease;
}
.item:hover {
  background-color: #00000025;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 13px;
}
.itemName {
}
.itemDescription {
  color: var(--gray-color);
  font-size: 10px;
}
.itemPrice {
  color: var(--gray-color);
  font-size: 11px;
  margin-left: auto;
}

.selectedItem {
  outline: 2px var(--primary-color) solid;
  background-color: var(--primary-color-opacity) !important;
  /* color: var(--primary-color); */
}

.disabled,
.disabled:hover {
  color: var(--gray-color);
  cursor: default;
  background-color: var(--border-color);
  opacity: 0.75;
}

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 17px;
  margin-bottom: var(--gap);
  height: 713px;
}
.cardTitle {
  font-size: 13px;
  font-weight: bold;
}

.sortingContainer {
  color: #6c7080;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.sortingContainer > span {
  /* border: 2px red solid; */
  flex: 0.33;
  width: fit-content;
  padding: 10px 0;
}
.sortingContainer > span:nth-child(2) {
  text-align: center;
}
.sortingContainer > span:last-child {
  text-align: right;
}

.lastTrade {
  padding: 12px 0;
  margin: 12px 0px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastTrade img {
  margin-left: 10px;
}

.tabsContainer {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: 15px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

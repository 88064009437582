.tabContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto 10% auto 25%;
  gap: 15px;
}

.tabContainer > h1 {
  text-align: center;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 260px;
}

.buttonsContainer > * {
  flex: 1;
  padding: 0 !important;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .tabContainer {
    margin: auto;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

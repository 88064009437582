.card {
  border-radius: 10px;
  padding: 30px;
  background-color: var(--secondary-color);
  height: 135px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.iconContainer {
  width: 60px;
  max-width: 61px;
  height: 60px;
  max-height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto 0;
}

.textContainer > span {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
}
.textContainer > p {
  font-size: 13px;
  color: var(--gray-color);
  margin: 0;
}

/* SMS */
.smsContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 32px;
  margin: 70px auto;
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
}

.smsIconContainer {
  width: 55px;
  height: 55px;
  border-radius: 14px;
  background-color: #00e6e6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.smsDescription {
  font-size: 11.5px;
}

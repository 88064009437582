.footerContainer {
    padding: 14.5px 0;
    border-top: 1px var(--border-color) solid;
    /* border-bottom: .4px var(--gray-color) solid; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 10px; */
}

.footerText {
    color: var(--gray-color);
}


/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 480px) {
    .footerContainer > *:first-child {
        display: none;
    }
    .footerContainer > *:last-child {
        margin: 0 auto;
        transform: scale(1.2);
    }
} 
/* ---------------------------------------- */
/* ---------------------------------------- */
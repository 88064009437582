/* disclaimer */
.disclaimer {
  color: var(--text-color);
  border-bottom: 1px var(--border-color) solid;
  font-size: 11px;
  text-align: left;
  padding-bottom: 3.5px;
  margin-bottom: 3.75px;
}

.disclaimerText {
  color: var(--gray-color);
  font-size: 10px;
}

/* User ID input */
.userIdInputLabel {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.divider {
  height: 20px;
  width: 1px;
  background-color: var(--border-color);
  margin: 0 10px;
}
.currencyLabel {
  color: var(--text-color) !important;
}

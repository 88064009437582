.slick-dots li button:before {
    transform: scale(1.4);
}

.dark .slick-dots li button:before {
    color: white;
    opacity: 1;
}

.light .slick-dots li button:before {
    color: black;
    opacity: 1;
}

.slick-slide img {
    display: unset;
}

.slick-active button:before {
    color: var(--primary-color) !important;
    opacity: 1;
}
.card {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    border-radius: 10px;
    padding: 30px 0;
}

.iconContainer {
    background-color: white;
    border-radius: 50%;
    height: 36.5px;
    width: 36.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusTitle {
    font-size: 29px;
    font-weight: bolder;
}

.cardButton {
    width: fit-content;
}

.verifiedInfoContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}
.verifiedInfoContainer > span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--gray-color);
    font-size: 11.3px;
}

.verifiedInfoContainer > span > div {
    width: 14.5px;
    height: 14.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
}

.pendingMessage {
    color: var(--gray-color);
    font-size: 11.5px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
    .statusTitle {
        text-align: center;
        font-size: 27px;
    }
    .pendingMessage {
        text-align: center;
    }
    .verifiedInfoContainer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}
/* ---------------------------------------- */
/* ---------------------------------------- */
.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.tabContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 25px;
  /* width: 78.5%; */
}
.pageContainer {
  background-color: black;
  color: white;
  padding: 50px 125px;
  display: flex;
  gap: 20px;
  /* height: 900px; */
}
.sideNavContainer {
  width: 270px;
  background-color: var(--secondary-color);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterRowContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin: 30px 0;
}

/* filter/date */
.filterContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  transition: color 0.3s ease;
  color: var(--gray-color);
}
.filterContainer:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.filterContainer > *:last-child {
  animation: arrowDown 0.3s ease forwards;
}
.filterContainer:hover > *:last-child {
  animation: arrowUp 0.3s ease forwards;
}
@keyframes arrowUp {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes arrowDown {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0);
  }
}

/* table */
.rowsContainer {
}

.tableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  font-size: 12.5px;
  padding: 0 0 0 6px;
}
.tableRow:hover {
  background-color: black;
}

.tableRow > span:nth-child(1) {
  flex: 0.23;
  display: flex;
  align-items: center;
  gap: 5px;
}
.tableRow > span:nth-child(2) {
  flex: 0.1;
}
.tableRow > span:nth-child(3) {
  flex: 0.15;
  color: var(--gray-color);
}
.tableRow > span:nth-child(4) {
  flex: 0.22;
}
.tableRow > span:nth-child(5) {
  flex: 0.15;
  color: var(--gray-color);
}
/* .tableRow > span:nth-child(6) {
    flex: .15;
    color: var(--gray-color);
} */
.tableRow > span:nth-child(6) {
  flex: 0.07;
}

/* MUI DATAGRID COLUMNS */
.pairColumn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.amountColumn {
  color: var(--gray-color);
}
.dateColumn {
  color: var(--gray-color);
}

.titlesContainer {
  display: flex;
  flex-direction: column;
}
.titlesContainer > *:last-child {
  color: var(--gray-color);
  font-size: 14px;
  font-weight: 300;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.mobileRowHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  font-weight: 500;
  font-size: 20px;
}
.mobileRowHeader img {
  height: 42px;
  width: 42px;
}

@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabHeader > *:last-child {
    width: 100%;
  }
  .titlesContainer > span {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

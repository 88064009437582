.pageContainer {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.conversionContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* gap: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  gap: 30px;
  margin: auto;
}

.inputContainer {
  border: 1px #ccced926 solid;
  border-radius: 5px;
  width: 100%;
  background-color: var(--secondary-color);
  padding: 10px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  margin: 100px 0;
}

/* Illustration */
.illustrationContainer {
  position: absolute;
  width: 1700px;
  z-index: -1;
}
.convertIlustrationContainer {
  width: 100%;
}

.convertUSDTIndicator {
  color: var(--gray-color);
  font-size: 10px;
}

.pageContainer {
  padding: 20px;
}

.formContainer {
  background-color: var(--secondary-color);
  padding: 25px;
  border-radius: 10px;
  max-width: 1000px;
  margin: 90px auto;
}
.formContainer > h1 {
  font-size: 30px;
  margin-bottom: 40px;
}
.formContainer > span {
  font-size: 20px;
  font-weight: 300;
}

.pageContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 746px) {
  .filterContainer {
    gap: 10px;
  }
}
@media (max-width: 700px) {
  .filterContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
  .filterContainer > * {
    width: 100%;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

.tabContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px var(--border-color) solid;
  margin-bottom: 20px;
}
.tabContainer > * {
  flex: 1;
  font-size: 18px;
}

.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: var(--gap);
  height: 171.9px;
}

.leftContainer {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  /* flex: .55; */
  width: fit-content;
  flex: 0.65;
  height: 100%;

  /* Old design */
  /* justify-content: space-around; */
  /* gap: 35px; */

  /* Compact design */
  justify-content: space-between;
}
.leftContainer > div:first-child {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
}
.leftContainer > div:first-child img {
  width: 40px;
}

.leftContainer > div:last-child {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 15px;
  /* padding-right: 10px;
  padding-right: 10%; */
}
.leftContainer > div:last-child > * {
  flex: 0.5 !important;
}

.rightContainer {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  white-space: nowrap;
}
/* header */
.rightContainer > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightContainer > div:first-child > div:first-child {
  font-size: 28px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
/* percentage change */
.rightContainer > div:first-child > div:first-child span {
  font-size: 12.5px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  gap: 2px;
}
.rightContainer > div:first-child > div:last-child {
  font-size: 12px;
  color: var(--gray-color);
  display: flex;
  align-items: center;
  gap: 5px;
}

/* time range selector row */
.rightContainer > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-color);
  font-size: 11.4px;
  margin-top: 20px;
}

/* bar container */
.rightContainer > div:nth-child(3) {
  width: 100%;
  height: 5px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  /* Compact design update */
  margin: -10px 0 -10px 0;
}
.rightContainer > div:nth-child(3) > div {
  position: absolute;
  background-color: var(--primary-color);
  left: 0;
  height: 100%;
  transition: all 0.3s ease;
}

/* high/low price */
.rightContainer > div:last-child {
  color: #6c7080;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectPairButton {
  width: fit-content;
  margin-left: auto;
  padding-right: 20px !important;
  font-weight: 500 !important;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1400px) {
  .rightContainer > *:first-child {
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: -20px;
  }
  .leftContainer > div:last-child > * {
    flex: 0.65 !important;
  }

  .rightContainer {
    flex: 0.35;
  }
}
/* @media (max-width: 1285px) { */
@media (max-width: 1200px) {
  .card {
    flex-direction: column;
  }
  .rightContainer {
    gap: 4px;
  }
  .leftContainer,
  .rightContainer {
    width: 100%;
  }
  .leftContainer > div:last-child {
    display: none;
  }
  .rightContainer > *:first-child {
    flex-direction: row;
    align-items: center !important;
    margin-bottom: 0px;
  }
  .rightContainer > div:first-child > div:first-child {
    font-size: 20px;
  }
  .selectPairButton {
    padding-right: 7px !important;
    white-space: nowrap;
  }
  .rightContainer > div:nth-child(3) {
    margin: 2px 0;
  }
  .rightContainer > div:nth-child(2) {
    margin-top: 5px;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

/* Bug Fix: Not fitting title for extra small devices */
@media (max-width: 350px) {
  .marketIdTag {
    font-size: 10.75px !important;
  }
  .leftContainer * {
    font-size: 12px;
  }
  .leftContainer > div:first-child img {
    width: 30px;
  }
}

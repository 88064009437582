.card {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.tabHeader {
  margin-bottom: 20px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.titlesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title {
}
.subTitle {
  font-size: 14px;
  color: var(--gray-color);
  transform: translateY(-5px);
  font-weight: 300;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.headerFirstRowContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.walletBalanceSpan {
  font-weight: 400;
  color: var(--text-color);
  font-size: 18px;
  display: none;
}
.hidePriceButton {
  height: 25px !important;
  background-color: var(--background-color);
  border: none;
  border-radius: 5px;
  color: #6c7080;
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 12px;
}

.walletBalanceSpan {
  font-weight: 400;
  color: var(--gray-color);
  font-size: 18px;
  display: none;
}

.accountBalanceContainer {
  margin: 15px 0;
}

.accountBalanceFirstRow {
  display: flex;
  align-items: center;
  gap: 20px;
}
.accountBalanceTitle {
  display: flex;
  align-self: center;
  justify-content: center;
  color: var(--gray-color);
  gap: 10px;
}
.accountBalanceBTC {
  color: var(--gray-color);
}
.accountBalanceUSDT {
  font-size: 23px;
}

.tabContainer {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 25px;
}
.tabHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.titlesContainer {
  display: flex;
  flex-direction: column;
}
.titlesContainer > *:last-child {
  font-size: 14px;
  color: var(--gray-color);
  font-weight: 300;
}

.filterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterRow {
  display: flex;
  gap: 6px;
  align-items: center;
  margin: 24px 0;
  justify-content: flex-end;
}

.tabsContainer {
  display: flex;
  align-items: center;
  height: 70px;
  gap: 20px;
  border-bottom: 1px var(--border-color) solid;
}
/* filtering buttons */
.filterRow button {
  background: none;
  border: none;
  color: #6c7080;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}
.filterRow button:hover {
  color: var(--primary-color);
}
.filterRow button:hover > *:last-child {
  animation: rotateArrowUp 0.4s forwards;
}
.filterRow button > *:last-child {
  animation: rotateArrowDown 0.4s forwards;
}
@keyframes rotateArrowUp {
  from {
    transform: rotateX(0);
  }
  to {
    transform: rotateX(180deg);
  }
}
@keyframes rotateArrowDown {
  from {
    transform: rotateX(180deg);
  }
  to {
    transform: rotateX(0);
  }
}
/* sorting row */
.sortingRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  margin: 0 0 15px 0;
  border-top: #6c7080 1px solid;
  border-bottom: #6c7080 1px solid;
  color: #6c7080;
  font-size: 12px;
  text-align: left;
}

.sortingRow > span:nth-child(1) {
  flex: 0.23;
}
.sortingRow > span:nth-child(2) {
  flex: 0.13;
}
.sortingRow > span:nth-child(3) {
  flex: 0.12;
}
.sortingRow > span:nth-child(4) {
  flex: 0.1;
}
.sortingRow > span:nth-child(5) {
  flex: 0.17;
}
.sortingRow > span:nth-child(6) {
  flex: 0.15;
}
.sortingRow > span:nth-child(7) {
  flex: 0.1;
}

.tableContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.tableContent > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12.5px;
}

/* images */
.tableContent > div > div:nth-child(1) img {
  width: 25px;
}
.tableContent > div > div:nth-child(2) img {
  width: 16px;
  margin: 0 5px;
}

/* table rows */
.tableContent > div > div:nth-child(1) {
  flex: 0.23;
  display: flex;
  align-items: center;
  gap: 7px;
}
.tableContent > div > div:nth-child(2) {
  flex: 0.13;
}
.tableContent > div > div:nth-child(3) {
  flex: 0.12;
  color: #6c7080;
}
.tableContent > div > div:nth-child(4) {
  flex: 0.1;
}
.tableContent > div > div:nth-child(5) {
  flex: 0.17;
}
.tableContent > div > div:nth-child(6) {
  flex: 0.15;
  color: #6c7080;
}
.tableContent > div > div:nth-child(7) {
  flex: 0.1;
}

.tableFooter {
  padding: 20px 0 15px 0;
  font-size: 12px;
  color: #6c7080;
}

/* MUI DATAGRID */
.coinColumn {
  display: flex;
  align-items: center;
  gap: 7px;
}
.coinColumn img {
  width: 25px;
}

.actionColumn img {
  width: 19px;
  margin-right: 10px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
.mobileRowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  font-size: 18px;
  gap: 10px;
  font-weight: bold;
}
.mobileRowHeader img {
  width: 40px;
}

@media (max-width: 820px) {
  .tabHeader h5 {
    display: none;
  }
  .tabHeader > *:last-child {
    width: 100%;
  }
  .titlesContainer > span {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.faqContainer {
    padding: 0 22.5%;
    font-size: 17px;
    margin: 30px 0;
}

.faqContainer h2 {
    text-align: center;
    font-size: 38px;
    margin: 25px 0  ;
}
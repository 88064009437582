.container {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  white-space: nowrap;
}
.container > span {
  white-space: nowrap;
}

.verified > *:last-child {
  color: var(--primary-color);
}

.checkmarkIcon {
  border-radius: 50%;
  background-color: white;
  width: 13px;
  height: 13px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending {
  color: var(--warning-color);
}
.pending > *:first-child {
  border-radius: 50%;
  background-color: white;
  width: 16px;
  height: 16px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pending > *:last-child {
}

.notVerified {
  color: var(--danger-color);
}

.header {
}

.container {
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -7px;
}
.priceFooter {
  font-weight: 500;
  font-size: 21px;
}

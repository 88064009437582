.modalBodyContainer {
    padding-top: 32px;
}

.modalBodyContainer > *:first-child {
    text-align: center;
}

.destinationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.destinationContainer > span {
    color: #6C7080;
}
.destinationContainer > div {
    border: 1px #6c7080a8 solid;
    width: 100%;
    padding: 0 0 0 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.destinationContainer > div > span {
    /* flex: 1; */
    /* margin-right: auto; */
    margin: 0 7px 0 5px;
    font-size: 10px;
}

.detailsContainer {
    border-top: 1px var(--border-color) solid;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.rowsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 300;
    font-size: 12px;
    gap: 7px;
}

.rowsContainer > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.row {
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.rowLabel {
  color: var(--gray-color);
}
.rowValue {
}

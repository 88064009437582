.card {
  /* background-color: var(--secondary-color); */
  border-radius: 10px;
  padding: 30px 0 30px 0;
  margin: var(--gap) 0;
  /* height: 580px; */
}

.radioButtonsContainer {
  display: flex;
  gap: 25px;
}

.mainBuySellContainer {
  display: flex;
  margin-top: 25px;
  gap: 30px;
  height: 85%;
}
.buyContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sellContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feesInfoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9295a6;
  font-size: 11px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buySellTabletButtonsContainer {
  display: none;
}

.buySellTabsContainer {
  min-width: 230px;
  margin: 0 auto 0 auto;
}

.buySellTabsContainer button {
  width: 50%;
  padding: 0 0 17px 0;
  background: none;
  border: none;
  color: #6c7080;
  border-bottom: 2px #ccced9 solid;
  font-size: 17px;
}

.buySellTabsContainer .selectedTab {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.balanceSpan {
  color: var(--gray-color);
  margin-top: 20px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 1030px) {
  .sellContainer {
    display: none;
  }
  .buySellTabletButtonsContainer {
    display: flex;
    gap: 20px;
  }
  .buyButton {
    display: none;
  }
}

@media (max-width: 990px) {
  .card {
    margin-top: 0;
  }
  .radioButtonsContainer {
    gap: 0px;
    justify-content: space-between;
    margin: 5px -12.5px 0 -12.5px;
  }
  .mainBuySellContainer {
    margin-top: 0;
  }
  .buySellTabsContainer {
    margin: -20px 0 20px 0;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.container {
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: 10px;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px var(--border-color) solid;
  padding-bottom: 2.5px;
  margin-bottom: 2.5px;
}

.divider {
  background-color: var(--border-color);
  height: 20px;
  width: 1px;
  border-radius: 5px;
}

.indicatorButton {
  background: none;
  border: none;
}

.timeframeButton {
  border: none;
  background: none;
  font-weight: 300;
}
.timeframeButton:hover {
  color: var(--primary-color);
}
@media (max-width: 379px) {
  .timeframeButton {
    font-size: 11.75px;
  }
  .headerContainer {
    gap: 1px;
  }
  .technicalIndicatorDivider {
    margin-left: auto;
  }
}

.selectedTimeframe {
  background-color: var(--primary-color-opacity);
  border-radius: 2px;
}

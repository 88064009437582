.MuiDrawer-paper {
    background-color: var(--secondary-color) !important;
    font-family: "Outfit", "Overpass", sans-serif;
}

.light .MuiDrawer-paper {
   color: #2B3144; 
}
.dark .MuiDrawer-paper {
    color: white; 
}
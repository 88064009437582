.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
  width: 75vw;
}

.headerContainer {
  text-align: center;
  margin-top: 105px;
  margin-bottom: 120px;
}

.headerContainer > span {
  color: var(--primary-color);
  font-size: 30px;
}
.headerContainer > h1 {
  font-size: 80px;
}
.headerContainer > p {
  color: var(--gray-color);
  font-size: 16px;
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer img {
  width: 70%;
  transform: scale(1.2) translateX(-80px);
}

.manualContainer h2 {
  font-size: 45px;
}
.manualContainer p {
  color: var(--gray-color);
  margin: 30px 0;
}
.manualContainer > *:last-child {
  width: fit-content !important;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
/* tab header */
@media (max-width: 1200px) {
  .pageContainer {
    width: 90vw;
  }
  .contentContainer img {
    width: 60%;
    transform: scale(1.1) translateX(-60px);
  }
}
@media (max-width: 840px) {
  .contentContainer {
    flex-direction: column-reverse;
    align-items: center;
  }
  .contentContainer img {
    width: 60%;
    transform: scale(1.4);
  }
  .manualContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

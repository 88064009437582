.container {
  max-width: 1100px;
  margin: 0 auto;
}
/* ---------- PAGE HEADER ---------- */
.pageHeader {
  text-align: center;
  padding: 80px 0;
  position: relative;
  width: 85%;
  margin: 0 auto;
}
.headerBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.pageHeader span {
  color: var(--primary-color);
  font-size: 20px;
}
.pageHeader h1 {
  font-weight: bold;
  font-size: 4rem;
  /* white-space: nowrap; */
}

.blogImg {
  background-color: lightgray;
  aspect-ratio: 16/9;
  width: 80%;
  margin: 70px auto 40px auto;
  border-radius: 20px;
}

.blogInfoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  width: 80%;
  margin: 0 auto;
}
.blogInfoContainer > span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.blogTitle {
  color: var(--primary-color);
  margin: 100px 0;
}
.contentContainer {
  margin: 0 100px;
}

.shimmerContainer {
  margin: 100px auto;
  width: 60%;
}

.categoriesContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.MuiAutocomplete-root *,
.MuiAutocomplete-listbox,
.MuiAutocomplete-noOptions {
  font-family: 'Outfit', 'Overpass', sans-serif !important;
  color: var(--text-color) !important;
  font-size: 16px !important;
}
.MuiAutocomplete-listbox {
  max-height: 220px !important;
}

.MuiAutocomplete-listbox > * {
  border-radius: 5px;
  margin: 6px 0;
}
.MuiAutocomplete-listbox > *:hover {
  cursor: pointer;
  background-color: none !important;
}

/* INPUT */
.MuiAutocomplete-root .MuiInputBase-root {
  background-color: var(--background-color) !important;
  border-radius: 5px;
  height: 42px;
  /* margin: 20px 0 30px 0; */
  padding: 0 12px !important;
  outline: 1.45px var(--secondary-color) solid;
}
.MuiAutocomplete-root input::placeholder {
  font-weight: 300 !important;
  font-size: 14px;
  color: var(--gray-color);
  opacity: 1;
}
.MuiAutocomplete-root .MuiInputBase-root:not(:focus-within) {
  transition: outline 0.3s ease;
}
.MuiAutocomplete-root .MuiInputBase-root:focus-within {
  outline-color: var(--primary-color);
}

/* AUTOCOMPLETE POPPER */
.MuiAutocomplete-noOptions {
  background-color: var(--background-color) !important;
  border-radius: 0;
}
.MuiAutocomplete-popper > .MuiAutocomplete-paper {
  max-height: 220px !important;
  overflow-y: hidden !important;
  border-radius: 5px;
  border: none !important;
  outline: none !important;
  background-color: var(--background-color) !important;
  box-shadow: none !important;
  margin-top: 1.5px;
}
.MuiAutocomplete-popper > .MuiAutocomplete-listbox {
  max-height: 220px;
  overflow-y: hidden !important;
}

/* OPTION */
.MuiAutocomplete-option {
  margin: 0 !important;
}
.MuiAutocomplete-option:hover {
  color: var(--primary-color);
  background: none !important;
}
.Mui-focused {
  background: none !important;
}
.MuiAutocomplete-option[aria-selected='true'],
.MuiAutocomplete-option[aria-selected='true'] .Mui-focused,
.MuiAutocomplete-option[aria-selected='true']:focus {
  background-color: transparent !important;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'],
.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] .Mui-focused {
  background-color: transparent !important;
}

/* ICON END */
.MuiAutocomplete-endAdornment * {
  color: var(--gray-color) !important;
}
.clearButtonAutocomplete {
}

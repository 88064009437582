.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
}

.headerContainer {
  text-align: center;
  margin-top: 105px;
  margin-bottom: 120px;
}

.headerContainer > span {
  color: var(--primary-color);
  font-size: 30px;
}
.headerContainer > h1 {
  font-size: 80px;
}
.headerContainer > p {
  color: var(--gray-color);
  font-size: 16px;
}

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
}

/* card */
.card {
  border-radius: 14px;
  background-color: var(--secondary-color);
  padding: 20px;
  height: 262px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  transition: outline-color 0.3s ease;
  outline: 1.75px transparent solid;
}
.card:hover {
  outline-color: var(--primary-color);
}

.card > div:first-child {
  background-color: var(--background-color);
  height: 75px;
  width: 75px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card > p {
  color: var(--gray-color);
  font-size: 13px;
}
.card > span {
  font-size: 17px;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
/* tab header */
@media (max-width: 1120px) {
  .pageContainer {
    padding: 0 8vw;
  }
  .card {
    width: 220px;
  }
}

@media (max-width: 820px) {
  .cardsContainer {
    flex-direction: column;
    gap: 150px;
  }
  .card {
    transform: scale(1.375);
    height: 235px;
  }
}
@media (max-width: 699px) {
  .headerContainer > h1 {
    white-space: nowrap;
    font-size: 45px;
  }
}
/* ---------------------------------- */
/* ---------------------------------- */

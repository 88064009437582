.card {
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.card h5 {
    margin-right: auto;
}

/* ------RESPONSIVENESS------ */
/* -------------------------- */
@media (max-width: 700px) {
    .card {
        padding: 0;
    }
}
/* -------------------------- */
/* -------------------------- */
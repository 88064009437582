.infoContainer {
  border: 1px var(--border-color) solid;
  border-radius: 5px;
  padding: 12px 6.5px;
  margin-bottom: 20px;
  height: 45px;
  background-color: var(--secondary-color);
}

.label {
  color: var(--gray-color);
  font-size: 11px;
}

.fromCoinContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fromCoinLeftContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.fromCoinLeftContainer img {
  width: 25px;
  height: 25px;
}
.fromCoinContainer > *:last-child {
  font-size: 10.75px;
  color: var(--gray-color);
}

.validAddress {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--success-color);
  font-size: 11px;
  text-align: right;
  margin-bottom: 10.5px;
}

/* SELECT NETWORK STYLING */
.menuItemContainer * {
  margin: 0;
}
.menuItemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
}

.menuItemContainer > p {
  color: white;
  font-size: 14px;
  margin: 0;
}

.menuItemContainer > div {
  color: var(--gray-color);
  font-size: 11.5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* disclaimer */
.disclaimer {
  color: var(--text-color);
  border-bottom: 1px var(--border-color) solid;
  font-size: 11px;
  text-align: left;
  padding-bottom: 3.5px;
  margin-bottom: 3.75px;
}

.disclaimerText {
  color: var(--gray-color);
  font-size: 10px;
}

.beneficiaryOptionRow {
  display: flex;
  flex-direction: column;
  justify-content: space-bsetween;
  gap: 10px;
  border-bottom: 1px var(--border-color) solid;
  padding: 10px;
}

.beneficiaryOptionRow > *:last-child {
  color: var(--gray-color);
  font-size: 10px;
}

.addBeneficiaryButton {
  /* width: 140px !important; */
  margin-top: -26px;
  margin-left: auto;
}

.button {
  background: transparent;
  border: 1px var(--border-color) solid;
  border-radius: 4px;
  padding: 5px 10px;
  width: 100px;
}

.selected {
  border-color: var(--primary-color);
}

.apr {
  margin-bottom: -5px;
  font-size: 14px;
}

.duration {
  font-weight: 300;
  color: var(--gray-color);
  font-size: 12px;
}

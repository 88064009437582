:root {
  --primary-color-opacity: #00e5e640;
  --primary-color: #00e6e6;
  --primary-color-light: #eafcfc;
  --secondary-color: #00191e;
  --text-color: #fff;
  --text-color-inverted: #051a1f;
  --gray-color: #9295a6;
  --positive-color: #03a66d;
  --negative-color: #dc2626;
  --success-color: #10b981;
  --danger-color: #ef4444;
  --warning-color: #f7931a;
  --background-color: #000;
  --footer-background-color: #022127;
  --background-color-inverted: #000;
  --horizontal-page-gap: 12.5%;
  --border-color: #ccced926;
}
.light {
  --primary-color: #00e6e6;
  --primary-color-light: #eafcfc;
  --secondary-color: #f4f9fc;
  --text-color: #051a1f;
  --text-color-inverted: #fff;
  --gray-color: #777987b0;
  --positive-color: #31bf8d;
  --negative-color: #e26363;
  --success-color: #10b981;
  --danger-color: #ef4444;
  --warning-color: #f7931a;
  --background-color: #fff;
  --footer-background-color: #fff;
  --background-color-inverted: #f4f9fc;
  --border-color: #b0b2bc5e; // Old #ccced926
  --horizontal-page-gap: 12.5%;
}

body {
  overflow: overlay;
}

a {
  color: var(--primary-color);
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}

button {
  color: var(--text-color);
  font-weight: 400;
}

.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

// .form-control::-webkit-input-placeholder {
//   /* Chrome/Opera/Safari */
//   color: #18214d59;
// }
// .form-control::-moz-placeholder {
//   /* Firefox 19+ */
//   color: #18214d59;
// }
// .form-control:-ms-input-placeholder {
//   /* IE 10+ */
//   color: #18214d59;
// }
// .form-control:-moz-placeholder {
//   /* Firefox 18- */
//   color: #18214d59;
// }

::-webkit-scrollbar {
  background: transparent;
  width: 5px; //3px original
  height: 6px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  // border: solid rgba(88, 88, 88, 0.12) 2px;
  border-radius: 10px;
}
.dark ::-webkit-scrollbar-thumb {
  background-color: black;
  box-shadow: inset 0 0 0px 1.5px black;
}
.dark ::-webkit-scrollbar {
  background: transparent;
  width: 5px; //3px original
  height: 6px;
  background-color: black;
}

// .light ::-webkit-scrollbar {
//   width: 3px;
// }
.light ::-webkit-scrollbar-thumb {
  background-color: white;
  box-shadow: inset 0 0 0px 1.5px white;
}
.light ::-webkit-scrollbar {
  background: transparent;
  width: 5px; //3px original
  height: 6px;
  background-color: white;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}
.dark * {
  scrollbar-color: var(--primary-color) transparent;
}
.light * {
  scrollbar-color: var(--primary-color) transparent;
}

.tabContainer {
  padding: 25px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.tabContainer > h5 {
  margin-bottom: 40px;
}

.tabHeader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 35px;
}
.tabHeader > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.tabHeader > div > span:first-child {
  font-size: 11px;
  color: var(--gray-color);
}
.tabHeader > div > span:last-child {
  font-size: 17px;
  font-weight: bold;
}

.KYCStatusContainer {
  display: flex;
  padding: 38px 0;
  border-top: 1px var(--border-color) solid;
  border-bottom: 1px var(--border-color) solid;
  margin: 45px 0;
}

.tabFooter {
  color: var(--gray-color);
  margin-top: auto;
  font-size: 12px;
}

/* VERIFY KYC TABLE */
.kycTableContainer {
  background-color: black;
  border-radius: 10px;
  padding: 30px 35px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.kycTableContainer > div {
  flex: 0.33;
  display: flex;
  flex-direction: column;
}
.kycTableContainer > div:nth-child(2) {
  border-left: 1px var(--border-color) solid;
  border-right: 1px var(--border-color) solid;
}
.kycTableContainer > div > span {
  padding: 0 20px;
}
.kycTableColumn:first-child > span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.kycTableColumn > span {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kycTableColumn > span > h3 {
  white-space: nowrap;
  font-size: 20px;
}
.kycTableColumn > span > span {
  color: var(--gray-color);
  font-size: 12px;
}

.kycTableColumnHeader {
  font-size: 24px;
  font-weight: bolder;
  white-space: nowrap;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 820px) {
  .tabContainer h5 {
    display: none;
  }
  .tabContainer {
    width: 90vw;
  }
}
@media (max-width: 820px) {
  .kycTableContainer {
    display: none;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}
.header {
  margin-top: 134px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftSection {
  display: flex;
  gap: 60px;
  align-items: flex-start;
}
.leftSection img {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 106px;
}
.jobDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.jobDetailsContainer p {
  color: var(--gray-color);
  font-size: 18px;
  max-width: 330px;
  margin-bottom: 25px;
}
.jobDetailsContainer span {
  color: var(--gray-color);
  font-size: 18px;
}
.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 180px;
}
.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.iconsContainer > * {
  color: var(--gray-color);
  transition: color 0.3s ease;
}

.applyFormSection {
  border-radius: 20px;
  background-color: var(--secondary-color);
  padding: 33px;
  margin-bottom: 50px;
}
.formHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.divider {
  height: 1.2px;
  background-color: var(--border-color);
  width: 100%;
  margin: 40px 0;
}

@media (max-width: 785px) {
  .header {
    flex-direction: column;
    gap: 40px;
    margin-top: 100px;
  }
  .header > *:first-child {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header > *:first-child * {
    margin: 0 auto;
    text-align: center;
    gap: 20px;
  }
  .header > *:last-child {
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }
}

.reCaptchaContainer {
  width: fit-content;
  margin: 40px auto 0 auto;
}

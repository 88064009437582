.MuiDataGrid-root {
  border: none !important;
  /* height: 400px !important; */
  font-family: 'Outfit', 'Overpass' !important;
  font-size: 13.5px !important; /* 12.5px */
  border-radius: 0 !important;
}

.MuiDataGrid-virtualScrollerContent {
  /* width: unset !important; */
}

.MuiDataGrid-columnHeaders {
  color: var(--gray-color) !important;
  border-top: 0.8px rgba(199, 199, 199, 0.216) solid !important;
  border-bottom: 0.8px rgba(199, 199, 199, 0.216) solid !important;
  border-radius: 0 !important;
  /* margin-bottom: 10px; */
}
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: 400 !important;
}

.MuiDataGrid-root .Mui-selected {
  background-color: transparent !important;
}

.MuiDataGrid-virtualScroller {
  z-index: 0;
  min-height: 300px;
  font-weight: 300; /* original 400 */
  padding: 20px 0;
}

.MuiDataGrid-row {
  border-radius: 5px;
  width: 99.5% !important;
}
.MuiDataGrid-row .Mui-selected {
  border: none;
}
.dark .MuiDataGrid-row {
  color: white;
}
.dark .MuiDataGrid-row:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.light .MuiDataGrid-row {
}
.light .MuiDataGrid-row:hover {
  background-color: white !important;
}

.MuiDataGrid-cell {
  border: none !important;
  border-width: 0 !important;
  border-bottom-width: 0 !important;
  padding-right: 0 !important;
}
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiSvgIcon-root {
  display: none !important;
}

/* GRIDTABLE FOOTER */
.MuiTablePagination-root {
  color: white !important;
}

/* ORDER TABLE */
.orders_container .MuiDataGrid-virtualScroller {
  height: 215.5px !important; /* 157.5px - original */
  min-height: 142.5px !important;
  overflow-y: scroll !important;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
/* MOBILE VIEW */
@media (max-width: 840px) {
  .orders_container .MuiDataGrid-virtualScroller {
    height: 370.5px !important;
    min-height: 142.5px !important;
    overflow-y: scroll !important;
  }
  .orders_container .MuiDataGrid-columnHeaders {
    display: none !important;
  }
  .orders_container .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
    max-height: 178px !important;
    min-height: 178px !important;
  }
  .orders_container .MuiDataGrid-row {
    max-height: 178px !important;
    min-height: 178px !important;
  }
}

/* COIN CARD */
.dark .MuiDataGrid-row:hover .coinTagContainer {
  background-color: var(--secondary-color);
}

.light .MuiDataGrid-row:hover .coinTagContainer {
  background-color: var(--secondary-color) !important;
}

/* TABLE BUTTON */
.light .MuiDataGrid-row:hover .tableButton {
  background-color: var(--secondary-color);
}

/* ---------------------------------------- */
/* ---------------------------------------- */

.orders_container .MuiDataGrid-main {
  /* width: 49vw; */
}

.orders_container .MuiDataGrid-main {
  /* width: 49vw; */
}

.subtitle {
  color: var(--gray-color);
  font-size: 10.5px;
}

.smsContainer {
  /* padding: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
}

.smsIconContainer {
  width: 55px;
  height: 55px;
  border-radius: 14px;
  background-color: #00e6e6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.smsDescription {
  font-size: 11.5px;
  margin-bottom: 17px;
}

.resendCodeButton {
  margin: 10px 0;
}

.containerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 5px 5px 10px;
  font-weight: 400;
}

.iconTextContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

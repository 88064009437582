.thumbnail {
  border-radius: 10px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerTitle {
  display: flex;
}

.App {
  font-family: sans-serif;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.code_input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code_label {
  margin-bottom: 16px;
}

.code_inputs {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.code_inputs input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  margin: 0 4px;
  /* border: 2px solid #4f5b66; */
  font-size: 20px;
  background-color: var(--secondary-color);
}

.code_inputs input:focus {
  outline: none;
}

.container {
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1.2fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.button {
  border: none;
  background: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 5px;
}
.button:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
}
.selectedType {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.chartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  height: 34px;
  border-radius: 5px;
}
.chartButton:hover {
  color: var(--primary-color);
}

.button {
  /* border-radius: 50%; */
  padding: 0;
  margin: 10px;
  outline: none;
  border: none;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.editContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000003a;
  filter: opacity(0);
  transition: filter 0.3s ease;
}

.button:hover .editContainer {
  filter: opacity(1);
}

.pageContainer {
  display: flex;
  width: 100%;
  height: 100dvh;
  overflow-y: hidden;
}

.illustrationContainer {
  flex: 0.34;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* illustration image */
.illustrationContainer img {
  width: 100%;
  transform: scale(1.5) translateX(15%) translateY(20px);
}

.tabContainer {
  flex: 0.66;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

/* logo image */
.tabContainer > img {
  min-width: 152px;
  width: 20%;
  margin-left: auto;
  margin-right: 10%;
  padding: 20px;
}

.formDiv {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 7% 20% 20% 35%;
}

.title {
  color: white;
  font-size: 33px;
  /* border: 1px blue solid; */
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
}

.mailInput {
  font-size: 16px;
  background-color: #00191e;
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  border: none;
  color: white;
}

.mailInput::placeholder {
  color: #9295a6;
}

.mailInput:focus {
  outline: 1px #00e6e6 solid;
}

.passwordInputContainer {
  padding: 9px 14px 7px 14px;
  border-radius: 8px;
  outline: none;
  margin: 20px 0;
  outline: none;
  background-color: #00191e;
  display: flex;
  align-items: center;
}

.passwordInputContainer:focus-within {
  outline: 1px #00e6e6 solid;
}

/* eye container */
.passwordInputContainer div {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.passwordInputContainer div:hover {
  background-color: #80808029;
  cursor: pointer;
}

.passwordInputContainer input {
  background-color: #00191e;
  border: none;
  width: 100%;
  color: white;
  font-size: 16px;
}

.passwordInputContainer input:focus {
  outline: none;
}

.passwordInputContainer input::placeholder {
  color: #9295a6;
}

.forgotPassword {
  color: #00e6e6;
  text-align: right;
  font-size: 12px;
}

.detailsSpan {
  color: white;
  text-align: left;
  margin-top: 5px;
  font-size: 12px;
  margin-bottom: 20px;
}

.registerSpan a {
  color: #00e6e6;
}

/* ------------ RESPONSIVENESS ------------ */
/* ---------------------------------------- */
@media (max-width: 825px) {
  .illustrationContainer {
    display: none;
  }
  .tabContainer {
    flex: 1;
    padding-top: 6vh;
  }
  .tabContainer > img {
    margin: 0 auto 0 8vw;
    width: 40%;
    min-width: 230px;
  }
  .pageContainer {
    overflow: auto;
    height: 100%;
  }
}
/* ---------------------------------------- */
/* ---------------------------------------- */

.MuiTooltip-tooltip,
.MuiTooltip-arrow::before {
  background-color: rgb(17, 17, 17) !important;
  font-family: "Outfit", "Overpass", sans-serif !important;
}

.MuiTooltip-tooltip {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.392);
}

.light .MuiTooltip-tooltip,
.light .MuiTooltip-arrow::before {
  background-color: #5d5d5d !important;
  font-family: "Outfit", "Overpass", sans-serif !important;
  /* color: var(--text-color); */
}

.light .MuiTooltip-tooltip {
  box-shadow: 0 0 2px #5d5d5d;
}

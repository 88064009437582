.passwordSection {
  padding: 30px 0;
  margin-top: 30px;
  border-top: var(--border-color) 1px solid;
}

.passwordSection > div:first-child {
  margin-bottom: 20px;
}

.smallLabel {
  color: #9295a6;
  font-size: 12px;
}

.newPasswordContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  margin-bottom: auto;
}
.newPasswordContainer > * {
  flex: 1;
}

.tabFooterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 550px) {
  .newPasswordContainer {
    flex-direction: column;
    gap: 0;
  }
  .newPasswordContainer > * {
    width: 100%;
  }
  .tabFooterContainer {
    flex-direction: column-reverse;
    text-align: center;
  }
}
